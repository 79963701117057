import { useEffect, useRef, useState } from "react";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import { Send, Close, ChatBubble, RestartAlt } from "@mui/icons-material";
import { palette } from "../../../theme";
import { ASSETS } from "../../../consts";
import MessageRenderer from "../message-renderer";
import { userSelector } from "../../../store/session/selectors";
import { useSelector } from "react-redux";
import { TypingIndicator } from "../typing-indicator";
import { usePostChatByConversationIdMessagesMutation, usePostChatMutation } from "../../../store/api/umbraco-api";

interface ChatProps {
  resourceId: number;
}

interface Message {
  sender: string;
  text: string;
}

const MobileChat = ({ resourceId }: ChatProps) => {
  const user = useSelector(userSelector);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isBotTyping, setIsBotTyping] = useState<boolean>(false); // Add state for bot typing
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const [postChat] = usePostChatMutation();
  const [postChatByConversationIdMessages] =
    usePostChatByConversationIdMessagesMutation();

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { sender: "User", text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsBotTyping(true); // Set typing indicator visible

    try {
      if (!conversationId) {
        const response = await postChat({
          createConversationRequest: { initialMessage: input, resourceId },
        }).unwrap();

        setConversationId(response.conversationId || null);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: response.message || "" },
        ]);
      } else {
        const response = await postChatByConversationIdMessages({
          conversationId,
          createMessageRequest: { message: input },
        }).unwrap();

        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: response.message || "" },
        ]);
      }
    } catch (err) {
      console.error("Error in message sending:", err);
    } finally {
      setIsBotTyping(false); // Hide typing indicator after receiving the response
    }
  };

  const handleStartOver = () => {
    setMessages([
      { sender: "AI", text: `היי ${user?.firstName}, במה אני יכול לעזור לך?` },
    ]);
    setConversationId(null);
    setIsBotTyping(false);
  };
  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesContainerRef.current) {
        const scrollElement = messagesContainerRef.current;
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    };

    // Immediate scroll
    scrollToBottom();

    // Delayed scroll to handle dynamic content
    const timeoutId = setTimeout(scrollToBottom, 100);

    return () => clearTimeout(timeoutId);
  }, [messages, isBotTyping, isVisible]);

  return (
    <>
      <IconButton
        onClick={() => setIsVisible((prev) => !prev)}
        sx={{
          position: "fixed",
          bottom: 16,
          padding: 0.3,
          borderRadius: 100,
          right: 16,
          zIndex: 1000,
          backgroundColor: isVisible
            ? palette.purple
            : palette.purple_light,

          color: isVisible
            ? palette.white
            : palette.purple,

          borderWidth: 1,
          borderColor: palette.purple,
          borderStyle: "solid",
          "&:hover": {
            color: palette.white,
            backgroundColor: palette.purple,
            borderColor: "#fff", // Optional: Change border color on hover
          },
        }}
      >
        {isVisible ? (
          <Close sx={{ padding: 1, fontSize: "20px" }} />
        ) : (
          <ChatBubble sx={{ padding: 1, fontSize: "20px" }} />
        )}
      </IconButton>

      {isVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: 80,
            right: 16,
            width: "90%",
            maxWidth: "400px",
            height: "60%",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            display: "flex",
            flexDirection: "column",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              padding: "12px",
              borderBottom: "1px solid #e0e0e0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <img
                src={ASSETS.AI_CHAT_IMAGE}
                alt="TAUGA AI"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  טאוגה
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  היעזר בטאוגה, עוזרת ההוראה מבינה מלאכותית{" "}
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={handleStartOver}
              sx={{
                color: palette.purple,
                "&:hover": {
                  backgroundColor:
                    palette.purple_light,
                },
              }}
            >
              <RestartAlt />
            </IconButton>
          </Box>

          <Box
            ref={messagesContainerRef}
            sx={{
              flex: 1,
              overflowY: "auto",
              padding: "16px",
              backgroundColor:
                palette.gray_extra_light,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  alignSelf:
                    message.sender === "User" ? "flex-start" : "flex-end",
                  color:
                    message.sender === "User"
                      ? palette.white
                      : palette.purple,
                  borderRadius: "12px",
                  backgroundColor:
                    message.sender === "User"
                      ? palette.purple
                      : palette.purple_light,
                  padding: "10px 16px",
                  wordBreak: "break-word",
                  marginY: 1,
                  maxWidth: "80%", // Limit maximum width of messages
                }}
              >
                <Typography variant="body2">
                  <MessageRenderer text={message.text} />
                </Typography>
              </Box>
            ))}

            <Box sx={{ alignSelf: "flex-end" }}>
              {isBotTyping && <TypingIndicator />}
            </Box>
          </Box>

          <Box
            sx={{
              padding: "12px",
              borderTop: "1px solid #e0e0e0",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              placeholder="כתוב הודעה..."
              variant="standard"
              InputProps={{ disableUnderline: true }}
            />
            <Button onClick={handleSendMessage}>
              <Send sx={{ transform: "rotate(180deg)" }} />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileChat;
