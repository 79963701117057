import { Box, Typography } from "@mui/joy";
import { palette } from "../../theme";
import { themeBase } from "../../theme";
import { AccessibilityNew } from "@mui/icons-material";
import LinkWithProvider from "../common/link";


// TODO: MOBILE, SHOULD BE STACK NOT FLEX
const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{

        backgroundColor: themeBase.colorSchemes.new_design.palette.white,
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        padding: 2,
        justifyContent: "space-between",
        alignItems: "center",
        borderTop: "1px solid",
        borderColor: "divider",
        mt: "auto", // Pushes footer to bottom if used in flex container
        paddingX: {
          xs: 2,
          sm: 4,
        },
        gap: { xs: 1.5, sm: 3 },
      }}
    >
      <Typography
        level="body-sm"
        sx={{
          color: "text.secondary",
          direction: "rtl",
        }}
      >
        © {new Date().getFullYear()} All rights reserved to Tauga AI
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          backgroundColor: themeBase.colorSchemes.new_design.palette.gray_light,
          paddingX: 1,
          paddingY: 0.25,
          borderRadius: 5,
          // color: themeBase.colorSchemes.new_design.palette.gray_dark,
          "&:hover": {
            color: themeBase.colorSchemes.new_design.palette.black,
          },
        }}
      >
        <LinkWithProvider 
          to="accessibility-statement"
          style={{ color: 'inherit', backgroundColor: "transparent" }}
        >
          הצהרת נגישות
        </LinkWithProvider>
      </Box>
    </Box>
  );
};

export default Footer;
