import { Card, Typography, Box } from "@mui/joy"
import RichText from "../../rich-text"
import { PropsWithChildren } from "react";

export const ImageAnswer = ({ imageUrls, ...answerProps } : AnswerProps & { imageUrls: string[]; }) => (
  <Answer {...answerProps}>
    <Typography level="title-md">התמונות שהעלית:</Typography>
    <Box>
      {imageUrls.map((url, index) => <img src={url} alt={`תמונה מס' ${index+1}`}/>)}
    </Box>
  </Answer>
);

export const TextAnswer = ({ text, ...answerProps } : AnswerProps & { text: string; }) => (
  <Answer {...answerProps}>
      {/* <Typography level="title-md">התשובה שלך:</Typography> */}
      <Typography level="body-md">{text}</Typography>
  </Answer>
);




interface AnswerProps extends PropsWithChildren { isSuccess?: boolean; score?: number; solution?: string | null }

const Answer = ({ isSuccess, score, solution, children } : AnswerProps) => (<><Card>
  {isSuccess !== undefined && <Typography level="title-md">{isSuccess ? "כל הכבוד" : "ניסיון יפה"}</Typography>}
  {score !== undefined && score >= 0 && <Typography level="body-md">הציון שקיבלת: {score}</Typography>}
  {children}
</Card>
{solution && <Card>
  <Typography level="title-md">הפתרון המלא:</Typography>
  <RichText content={solution}/>
</Card>}
</>);

  
export default Answer;