import { Box, Button, Typography } from "@mui/joy";
import useNavigateWithProvider from "../hooks/use-navigate-with-provider";

const NotFound = () => {
  const navigate = useNavigateWithProvider();
  return (
    <Box sx={{
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      padding: 16
    }}>
      <Typography level="title-md">לא מצאנו את מה שביקשת</Typography>
      <Button onClick={() => navigate("/")}>חזרה לעמוד הראשי</Button>
    </Box>
  );
};

export default NotFound;
