import { Box, Typography, IconButton, CircularProgress } from "@mui/joy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import useNavigateWithProvider from "../../hooks/use-navigate-with-provider";
import { useParams } from "react-router-dom";
import { palette } from "../../theme";
import {
  useGetCoursesByCourseIdChaptersAndChapterIdQuery,
  Feature,
  FeatureType,
} from "../../store/api/umbraco-api";
import { PrimaryActionButton } from "../../components/units/shared/btn";
import { ArrowBack } from "@mui/icons-material";

const ChapterScreen = () => {
  const { chapterId, courseId } = useParams();
  const navigate = useNavigateWithProvider();
  const [expandedFeature, setExpandedFeature] = useState<number | null>(null);

  const {
    data: chapter,
    isLoading,
    error,
  } = useGetCoursesByCourseIdChaptersAndChapterIdQuery(
    {
      courseId: parseInt(courseId || "0"),
      chapterId: parseInt(chapterId || "0"),
    },
    {
      skip: !courseId || !chapterId,
    }
  );

  const mapNumberToFeatureType = (type: number): FeatureType => {
    switch (type) {
      case 0:
        return "SmartExercise";
      case 1:
        return "Lesson";
      case 2:
        return "SmartLesson";
      default:
        return "Lesson"; // Default fallback, adjust as needed
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.log("error:", error);
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="outlined" color="danger">
          Error loading chapter
        </Typography>
      </Box>
    );
  }

  const getFeaturePath = (feature: Feature) => {
    const featureType =
      typeof feature.type === "number"
        ? mapNumberToFeatureType(feature.type)
        : feature.type;

    switch (featureType) {
      case "SmartExercise":
        return `/course/${courseId}/chapter/${chapterId}/exercise/${feature.id}`;
      case "Lesson":
        return `/course/${courseId}/chapter/${chapterId}/lesson/${feature.id}`;
      case "SmartLesson":
        return `/course/${courseId}/chapter/${chapterId}/smart-lesson/${feature.id}`;
      default:
        return `/course/${courseId}/chapter/${chapterId}`;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "auto",
        padding: { xs: 2, md: 4 },
      }}
    >
      <Box sx={{ maxWidth: "1200px", width: "100%", margin: "0 auto" }}>
        {/* Chapter Header Section */}
        <Box sx={{ mb: 6 }}>
          <Typography
            level="h1"
            sx={{
              color: palette.black,
              mb: 2,
            }}
          >
            {chapter?.title}
          </Typography>
          <Typography
            level="body-lg"
            sx={{
              color: palette.gray_dark,
              mb: 4,
              fontSize: "1.25rem",
            }}
          >
            כל השיעורים והתרגילים בפרק {chapter?.title}
          </Typography>
        </Box>

        {/* Table Header */}
        <Box
          sx={{
            display: { xs: "none", md: "grid" },
            gridTemplateColumns: "60px 2fr 2fr 1fr 140px",
            gap: 3,
            padding: "40px 40px",
            backgroundColor: "white",
            borderBottom: `1px solid ${palette.gray_light}`,
            borderRadius: "12px 12px 0 0",
          }}
        >
          <Box />
          <Typography
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              fontSize: "1.25rem",
            }}
          >
            שם
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              fontSize: "1.25rem",
            }}
          >
            התקדמות
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              color: "text.secondary",
              fontSize: "1.25rem",
            }}
          >
            זמן שתורגל
          </Typography>
          <Box />
        </Box>

        {/* Features List */}
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "0 0 12px 12px",
            overflow: "hidden",
          }}
        >
          {chapter?.features?.map((feature: Feature) => (
            <Box
              key={feature.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderBottom: `1px solid ${palette.gray_light}`,
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <Box
                sx={{
                  display: "grid",

                  gridTemplateColumns: {
                    xs: "1fr auto",
                    md: "60px 2fr 2fr 1fr 140px",
                  },
                  gap: { xs: 2, md: 3 },
                  paddingX: { xs: 1, md: 5 },
                  paddingY: { xs: 2, md: 4 },
                  alignItems: "center",
                  cursor: feature.description ? "pointer" : "default",
                  transition: "background-color 0.2s ease",
                  "&:hover": {
                    backgroundColor: palette.purple_light,
                  },
                }}
                onClick={() =>
                  feature.description &&
                  setExpandedFeature(
                    expandedFeature === feature.id ? null : feature.id || null
                  )
                }
              >
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: "1.1rem", md: "1.25rem" },
                    }}
                  >
                    {feature.name}
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "8px",
                        backgroundColor: palette.purple_light,
                        borderRadius: "12px",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: `${
                            ((feature.completedUnitNumber || 0) /
                              (feature.totalUnitNumber || 1)) *
                            100
                          }%`,
                          height: "100%",
                          backgroundColor: palette.purple,
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        minWidth: "60px",
                        fontSize: { xs: "0.9rem", md: "1.15rem" },
                        color: "text.secondary",
                      }}
                    >
                      {feature.completedUnitNumber || 0}/
                      {feature.totalUnitNumber || 0}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: { xs: "0.9rem", md: "1.15rem" },
                    }}
                  >
                    {feature.learningMinutes || 0} דקות
                  </Typography>
                </Box>

                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  {feature.description ? (
                    <IconButton
                      variant="plain"
                      sx={{
                        color: "text.secondary",
                        transform:
                          expandedFeature === feature.id
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                        transition: "transform 0.2s ease",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  ) : (
                    <Box />
                  )}
                </Box>

                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "1.25rem" }}>
                    {feature.name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "10px",
                      backgroundColor: palette.purple_light,
                      borderRadius: "12px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${
                          ((feature.completedUnitNumber || 0) /
                            (feature.totalUnitNumber || 1)) *
                          100
                        }%`,
                        height: "100%",
                        backgroundColor: palette.purple,
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      minWidth: "60px",
                      fontSize: { xs: "0.9rem", md: "1.15rem" },
                      color: "text.secondary",
                    }}
                  >
                    {feature.completedUnitNumber || 0}/
                    {feature.totalUnitNumber || 0}
                  </Typography>
                </Box>

                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: { xs: "0.9rem", md: "1.15rem" },
                    }}
                  >
                    {feature.learningMinutes || 0} דקות
                  </Typography>
                </Box>

                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <PrimaryActionButton
                    text="התחל"
                    iconSide="left"
                    icon={<ArrowBack />}
                    onClick={() => navigate(getFeaturePath(feature))}
                  />
                </Box>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" }, padding: 2 }}>
                <PrimaryActionButton
                  text="התחל"
                  iconSide="left"
                  icon={<ArrowBack />}
                  onClick={() => navigate(getFeaturePath(feature))}
                />
              </Box>
              {/* Description section */}
              <Box
                sx={{
                  height: expandedFeature === feature.id ? "auto" : 0,
                  overflow: "hidden",
                  transition: "height 0.2s ease",
                  backgroundColor: "#F8F9FA",
                }}
              >
                {feature.description && (
                  <Box
                    sx={{
                      padding:
                        expandedFeature === feature.id
                          ? { xs: "20px 16px", md: "24px 32px 24px 74px" }
                          : { xs: "0 16px", md: "0 32px 0 74px" },
                      opacity: expandedFeature === feature.id ? 1 : 0,
                      transition: "all 0.2s ease",
                      borderLeft: "2px solid transparent",
                      "&:hover": {
                        borderLeft: `2px solid ${palette.purple}`,
                      },
                    }}
                  >
                    <Typography
                      level="body-md"
                      sx={{
                        color: "text.secondary",
                        whiteSpace: "pre-wrap",
                        fontSize: { xs: "1rem", md: "1.15rem" },
                        lineHeight: "1.6",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChapterScreen;
