import { Box } from "@mui/joy";
import { ReactNode } from "react";
import { palette } from "../../theme";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useSidebar } from "../../context/sidebar-context";

interface ThreeColumnLayoutProps {
  sidebar: ReactNode;
  main: ReactNode;
  rightPanel?: ReactNode;
  isMobile?: boolean;
}

const ThreeColumnLayout = ({
  sidebar,
  main,
  rightPanel,
  isMobile = false,
}: ThreeColumnLayoutProps) => {
  const { setIsOpen, isOpen } = useSidebar();

  return (
    <Box sx={styles.container(isMobile)}>
      <Box sx={isMobile ? { width: "0px", display: "none" } : styles.sidebar}>
        {sidebar}
      </Box>
      <Box sx={styles.main}>
        {isMobile && (
          <Box sx={styles.header}>
            <IconButton  onClick={() => setIsOpen(true)} sx={styles.menuButton}>
              <Menu sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        )}
        <Box sx={styles.content}>{main}</Box>
      </Box>
      {rightPanel}
    </Box>
  );
};

const styles = {
  container: (isMobile: boolean) => ({
    display: "flex",
    paddingX: 1,
    gap: 1,
    width: "100%",
    height: "75vh",
    paddingY: isMobile ? 2 : 1,
  }),
  sidebar: {
    width: "300px",
    borderColor: palette.gray_light,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "16px",
    backgroundColor: palette.white,
    overflow: "hidden",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: palette.gray_light,
    borderStyle: "solid",
    backgroundColor: palette.white,
    flex: 1,
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 1.5,
    borderBottom: `1px solid ${palette.gray_light}`,
  },
  content: {
    flex: 1,
    padding: 2,
    overflow: "auto",
  },
  menuButton: {
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray_light}`,
    "&:hover": {
      backgroundColor: palette.gray_extra_light,
    },
  },
};

export default ThreeColumnLayout;
