import { useContext } from "react";
import { UnitContentResponse, UnitContext } from "./unit-context";
import { LessonContext } from "./lesson-context";
import { ExerciseContext } from "./exercise-context";

const useUnit = <TData extends UnitContentResponse = UnitContentResponse,>() : UnitContext<TData> => {
    const lessonContext = useContext(LessonContext);
    const exerciseContext = useContext(ExerciseContext);

    const context = lessonContext || exerciseContext;
    
    if (context) return context as unknown as UnitContext<TData>;

    throw new Error("No context");    
};

export default useUnit;