import { Box, Button, TextField } from "@mui/material";

import { Send } from "@mui/icons-material";
import { palette } from "../../theme";

export const ChatInput = ({
  input,
  setInput,
  handleSendMessage,
}: {
  input: string;
  setInput: (input: string) => void;
  handleSendMessage: () => void;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: palette.gray_extra_light,
        border: `1px solid ${palette.gray_light}`,
        padding: "16px",
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <TextField
        fullWidth
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        placeholder="כתוב הודעה..."
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
      />
      <Button
        onClick={handleSendMessage}
        sx={{
          color: palette.purple,
          ":hover": {
            backgroundColor: palette.purple_light,
            borderRadius: "20%",
          },
        }}
      >
        <Send sx={{ transform: "rotate(180deg)" }} />
      </Button>
    </Box>
  );
};
