import { useState, useEffect } from "react";
import { Box } from "@mui/joy";
import { IconButton } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import {
  IUnitCompleted,
  umbracoApi,
} from "../../store/api/umbraco-api";
import { UnitProps } from "./interfaces";
import { AIConversationExercise } from "./types";
import Error from "./shared/error";
import Loading from "./shared/loading";
import { palette } from "../../theme";
import useUnit from "../../context/use-unit";
import { UnitContentResponse } from "../../context/unit-context";
import { ChatInput } from "../chat/chat-input";
import { MessagesList } from "../chat/messages-list";
import { BoxProps } from "@mui/joy";
import { UnitHeader } from "./unit-header";
import { streamMessage } from "../../services/openai";

interface Message {
  sender: string;
  text: string;
}

interface AIConversationResponse extends UnitContentResponse {
  unit: AIConversationExercise;
  completion: IUnitCompleted & { conversationId: string };
}

interface AIConversationProps extends UnitProps, BoxProps {
  contentRef?: React.RefObject<HTMLDivElement>;
}

const AIConversation = ({ unitId, contentRef }: AIConversationProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [streamingMessage, setStreamingMessage] = useState<string>("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);

  const { unitContent, unitCompletion } = useUnit<AIConversationResponse>();

  const [postChat, postChatState] = umbracoApi.usePostChatMutation();
  const [getMessages] = umbracoApi.useLazyGetChatByConversationIdMessagesQuery();

  const handleStream = (conversationId: string, message: string) => {
      let fullMessage = '';
      return streamMessage({
        conversationId,
        message
      },
      chunk => {
        fullMessage += chunk;
        setStreamingMessage(fullMessage);
      },
      () => {
        setMessages(prev => [...prev, { sender: "AI", text: fullMessage }]);
        setStreamingMessage("");
        setIsStreaming(false);
        setIsBotTyping(false);  
      });
    };

  // conversation setup
  useEffect(() => {
    if (
      conversationId ||
      !unitContent.data ||
      unitContent.data.unit.id !== unitId ||
      unitCompletion.response 
    )
      return;
    
    const initializeConversation = async () => {
      if (unitContent.data?.isCompleted && unitContent.data.completion.conversationId) {
        setConversationId(unitContent.data.completion.conversationId);
        // Fetch existing messages
        const data = await getMessages({ conversationId: unitContent.data.completion.conversationId }).unwrap();

        if (data.length >= 1) {
          setMessages(
            data.slice(1).map((m: any) => ({
              sender: (m.sender as unknown as number) === 0 ? "AI" : "User",
              text: m.content || "",
            }))
          );
        }
      } else {
        if (!postChatState.isUninitialized) return;
        // Create new conversation
        const data = await postChat({ createConversationRequest: {
            resourceId: unitId,
            initialMessage: "INITIAL"
          }}).unwrap();

        setConversationId(data.conversationId || null);
        setMessages([
          { sender: "AI", text: data.message || "Initial response" },
        ]);
      }
    };

    initializeConversation().catch(console.error);
  }, [unitContent, unitCompletion]);

  const handleSendMessage = async () => {
    if (!input.trim() || !conversationId || isStreaming) return;

    const userMessage: Message = { sender: "User", text: input };
    const currentInput = input;
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsBotTyping(true);

    try {
      setIsStreaming(true);
      setStreamingMessage("");
      await handleStream(conversationId, currentInput);

      // set unit as copmleted if its the first message
      if (unitContent.data?.completion == null && unitCompletion.response == undefined) {
        unitCompletion.post({
          id: unitId,
          type: "AIConversation",
          start: new Date().toISOString(),
          end: new Date().toISOString(),
          conversationId,
        } as IUnitCompleted);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setIsBotTyping(false);
      setIsStreaming(false);
    }
  };

  const handleReset = async () => {
    if (!conversationId) return;
    
    try {
      const data = await postChat({ createConversationRequest: {
        resourceId: unitId,
        initialMessage: "INITIAL"
      }}).unwrap();

      setConversationId(data.conversationId || null);
      setMessages([
        { sender: "AI", text: data.message || "Initial response" },
      ]);
    } catch (err) {
      console.error("Error during reset:", err);
    }
  };

  if (!unitContent.data || unitContent.isLoading) {
    return <Loading />;
  }

  if (unitContent.isError) {
    return <Error />;
  }

  const { unit } = unitContent.data as AIConversationResponse;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <UnitHeader title={unit.title} content={unit.content} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: palette.white,
          borderRadius: "16px",
          borderWidth: 1,
          width: "100%",
          flex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            padding: "16px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            backdropFilter: "blur(8px)",
            backgroundColor: palette.white,
            borderBottom: `1px solid ${palette.gray_light}`,
          }}
        >
          <IconButton onClick={handleReset} disabled={isStreaming}>
            <RestartAlt />
          </IconButton>
        </Box>

        <Box
          sx={{
            flex: 1,
            backgroundColor: palette.gray_extra_light,
            padding: "16px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MessagesList
            messages={messages}
            isBotTyping={isBotTyping}
            contentRef={contentRef}
            streamingMessage={streamingMessage}
            isStreaming={isStreaming}
          />
        </Box>

        <Box
          sx={{
            padding: "16px",
            borderTop: `1px solid ${palette.gray_light}`,
            backgroundColor: palette.white,
          }}
        >
          <ChatInput
            input={input}
            setInput={setInput}
            handleSendMessage={handleSendMessage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AIConversation;
