import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { CssVarsProvider } from "@mui/joy";
import { persistor, store } from "./store";
import UnauthenticatedRoute from "./components/route-guards/unauthenticated-route";
import AuthenticatedRoute from "./components/route-guards/authenticated-route";
import Login from "./screens/login";
import { theme } from "./theme";
import NotFound from "./screens/not-found";
import Signup from "./screens/signup";
import CourseScreen from "./screens/course";
import ChapterScreen from "./screens/chapter";
import ExerciseScreen from "./screens/course/feature/exercise";
import LessonScreen from "./screens/course/feature/lesson";
import AccessibilityDeclaration from "./screens/rothchild/accessibility-declaration";
import { MathJaxContext } from "better-react-mathjax";
import { SidebarProvider } from "./context/sidebar-context";

const queryClient = new QueryClient({});

const withOptionalProvider = (path: string, element: JSX.Element) => [
  { path, element },
  { path: `/:provider${path}`, element },
];

// Unauthenticated routes
const unauthenticatedRoutes = [
  ...withOptionalProvider("/login", <Login />),
  ...withOptionalProvider("/signup", <Signup />),
];

// Authenticated routes
const authenticatedRoutes = [
  { path: "/", element: <CourseScreen /> },
  { path: "/course/:courseId/chapter/:chapterId", element: <ChapterScreen /> },
  {
    path: "/course/:courseId/chapter/:chapterId/lesson/:featureId",
    element: <LessonScreen />,
  },
  {
    path: "/course/:courseId/chapter/:chapterId/exercise/:featureId",
    element: <ExerciseScreen />,
  },
  { path: "/accessibility-statement", element: <AccessibilityDeclaration /> },
  { path: "*", element: <NotFound /> },
];

// Combine into router
const router = createBrowserRouter([
  {
    element: <UnauthenticatedRoute />,
    children: unauthenticatedRoutes,
  },
  {
    element: <AuthenticatedRoute />,
    children: authenticatedRoutes,
  },
]);

function AppRouter() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssVarsProvider theme={theme} />
        <QueryClientProvider client={queryClient}>
          <MathJaxContext>
            <SidebarProvider>
              <RouterProvider router={router} />
            </SidebarProvider>
          </MathJaxContext>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppRouter;
