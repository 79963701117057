import { useMemo, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, CircularProgress, LinearProgress } from "@mui/joy";
import { useMediaQuery } from "@mui/material";

import MobileChat from "../../../../components/chat/side-chat/mobile-chat";
import UnitSwitcher from "../../../../components/units/unit-switcher";
import ExerciseSidebar from "./exercise-side-bar";
import { palette } from "../../../../theme";
import { UnitTypes } from "../../../../components/units/types";
import { ExerciseProvider } from "../../../../context/exercise-context";
import useExercise from "../../../../context/use-exercise";
import FeatureFinished from "../feature-finished";
import ResizableChat from "../../../../components/chat/resizable-chat";
import ThreeColumnLayout from "../../../../components/layouts/ThreeColumnLayout";

const ExerciseScreen = () => {
  const {
    isLoading,
    isError,
    exercise: exerciseData,
    units: unitsData,
    unitContent,
    moveForward,
    moveToIndex,
    moveNext,
    currentIndex,
    currentUnit,
    unitCompletion,
  } = useExercise();
  const isMobile = useMediaQuery("(max-width:1000px)");

  const contentRef = useRef<HTMLDivElement>(null);


  const isChat = useMemo(
    () =>
      unitContent.data &&
      (unitContent.data.unit?.type as unknown as UnitTypes) !==
        UnitTypes.AIConversation,
    [unitContent]
  );

  const chatPanel =
    isChat &&
    (isMobile ? (
      <MobileChat resourceId={unitContent.data?.unit?.id || 0} />
    ) : (
      <ResizableChat resourceId={unitContent.data?.unit?.id || 0} />
    ));

  if (isLoading) return <LinearProgress />;
  if (isError) return <div>Error loading lesson or units</div>;

  return (
    <ThreeColumnLayout
      isMobile={isMobile}
      sidebar={
        <ExerciseSidebar
          name={exerciseData?.name || ""}
          description={exerciseData?.description || ""}
          setSelectedUnit={moveToIndex}
          totalUnits={exerciseData?.totalUnits || 0}
          units={unitsData}
          completedUnits={exerciseData?.completedUnits || 0}
          isMobile={isMobile}
          currentIndex={currentIndex}
          currentUnit={
            !unitContent.data?.isCompleted && currentUnit?.name
              ? { name: currentUnit.name }
              : null
          }
          moveToCurrent={moveForward}
        />
      }
      main={
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          {unitContent.isLoading ? (
            <CircularProgress size="sm" color="primary" />
          ) : (
            unitContent.data?.unit?.id && (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={styles.unitSwitcher}>
                  <UnitSwitcher
                    contentRef={contentRef}
                    key={`${unitContent.data.unit.id}_${unitContent.data.isCompleted}`}
                    unitId={unitContent.data?.unit?.id}
                    unitType={unitContent.data?.unit?.type || "Form"}
                  />
                </Box>
                <Box sx={styles.buttonContainer}>
                  <Button
                    sx={styles.button}
                    onClick={currentIndex === null ? moveNext : moveForward}
                    disabled={unitCompletion.isLoading}
                  >
                    {unitContent.data?.isCompleted ? "קדימה" : "דלג"}
                  </Button>
                </Box>
              </Box>
            )
          )}
          {!currentUnit &&
            currentIndex === null &&
            (exerciseData?.status as unknown as number) === 2 && (
              <FeatureFinished message={exerciseData?.finishMessage} />
            )}
        </Box>
      }
      rightPanel={chatPanel}
    />
  );
};

const ExerciseScreenWithContext = () => {
  const { featureId } = useParams();
  const exerciseId = featureId ? parseInt(featureId) : 0;

  return (
    <ExerciseProvider exerciseId={exerciseId}>
      <ExerciseScreen />
    </ExerciseProvider>
  );
};

export default ExerciseScreenWithContext;

const styles = {
  unitSwitcher: {
    flex: 1,
    overflowY: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mt: 2,
    flexShrink: 0,
  },
  button: {
    backgroundColor: palette.purple,
    color: palette.white,
    "&:hover": {
      backgroundColor: palette.purple_dark,
    },
  },
};
