import { Feature } from "../../store/api/umbraco-api";
import { Link } from "react-router-dom";
import { FeatureWithNumericType } from "../../screens/course";
import { Typography, Box } from "@mui/joy";
import { palette } from "../../theme";

interface ChapterCardProps {
  courseId: number;
  id: number;
  title?: string;
  total?: number;
  completed?: number;
  learningMinutes?: number;
  features?: FeatureWithNumericType[]; // Use the updated Feature type
}

const ChapterCard = ({
  courseId,
  title = "Untitled",
  id = 0,
  total = 0,
  completed = 0,
  learningMinutes = 0,
  features = [],
}: ChapterCardProps) => {
  const validFeatures = features.filter(
    (feature) => feature.type !== undefined
  );

  const getFeaturePath = () => {
    if (validFeatures.length > 1) {
      return `/course/${courseId}/chapter/${id}`;
    }

    // Use validFeatures instead of features
    switch (validFeatures[0]?.type) {
      case 0:
        return `/course/${courseId}/chapter/${id}/exercise/${validFeatures[0].id}`;
      case 1:
        return `/course/${courseId}/chapter/${id}/lesson/${validFeatures[0].id}`;
      case 2:
        return `/course/${courseId}/chapter/${id}/smart-lesson/${validFeatures[0].id}`;
      default:
        return `/course/${courseId}/chapter/${id}`;
    }
  };

  return (
    <Link
      style={{ textDecoration: "none" }}
      state={{
        features: validFeatures as unknown as Feature[], // Use filtered features
        chapterId: id,
        chapterTitle: title,
        chapter: {
          id,
          title,
          total,
          completed,
          learningMinutes,
          features: validFeatures, // Use filtered features
        },
      }}
      to={getFeaturePath()}
    >
      <Box
        sx={{
          minHeight: "60px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3,
          borderRadius: "12px",
          bgcolor: palette.white,
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.75rem",
              color: palette.purple,
            }}
          >
            {completed}/{total}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "10px",
            backgroundColor:
              palette.purple_light,
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: `${total > 0 ? (completed / total) * 100 : 0}%`,
              height: "100%",
              backgroundColor: palette.purple,
              transition: "width 0.3s ease-in-out",
            }}
          />
        </Box>
      </Box>
    </Link>
  );
};

export default ChapterCard;
