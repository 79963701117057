import { Box, BoxProps, Typography } from "@mui/joy";
import RichText from "../rich-text";
import { UnitProps } from "./interfaces";
import { IUnit } from "../../store/api/umbraco-api";
import Loading from "./shared/loading";
import Error from "./shared/error";
import { UnitContentResponse } from "../../context/unit-context";
import { CheckCircle } from "@mui/icons-material";
import useElapsedTime from "../../hooks/use-elapsed-time";
import useUnit from "../../context/use-unit";
import { PrimaryActionButton } from "./shared/btn";

export interface ContentUnitData extends UnitContentResponse {
  unit: IUnit & {
    title: string;
    content: string;
  };
}

const ContentUnit = ({ unitId, ...props }: UnitProps & BoxProps) => {
  const { unitContent, unitCompletion } = useUnit<ContentUnitData>();

  const fetchTime = useElapsedTime(); // Use the custom hook to track the start time

  const handleFinishUnit = async () => {
    unitCompletion.post({
      id: unitId,
      type: "ContentUnit", // or the correct type for your unit
      start: fetchTime.toISOString(), // fetchTime is guaranteed to be a Date
      end: new Date().toISOString(),
    });
  };

  if (!unitContent.data || unitContent.isLoading) {
    return <Loading />;
  }

  if (unitContent.isError) {
    return <Error />;
  }

  const { unit, isCompleted } = unitContent.data;
  return (
    <Box {...props}>
      {unit?.title && (
        <Typography variant="plain" gutterBottom>
          {unit.title}
        </Typography>
      )}
      {unit?.content && <RichText content={unit.content} />}
      {!isCompleted && (
        <PrimaryActionButton
          text="סיום קריאה"
          icon={<CheckCircle />}
          disabled={unitCompletion.response !== undefined}
          onClick={handleFinishUnit}
        />
      )}
    </Box>
  );
};

export default ContentUnit;
