import { REACT_APP_SERVER_DOMAIN } from "../base-env";

interface StreamOptions {
  conversationId: string;
  message: string;
}

export const streamMessage = async (
  options: StreamOptions,
  onChunk: (chunk: string) => void,
  onDone?: () => void
) => {
  try {
    const response = await fetch(
      `${REACT_APP_SERVER_DOMAIN}/chat/${options.conversationId}/messages?stream=true`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({ message: options.message })
      }
    );

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { value, done } = await reader!.read();
      if (done) {
        onDone?.();
        break;
      }
      const chunk = decoder.decode(value, { stream: true });
      onChunk(chunk);
    }
  } catch (error) {
    console.error('Stream error:', error);
    throw error;
  }
};
