import { createContext, PropsWithChildren, useEffect, useState } from "react";
import {
  GetLessonsByLessonIdUnitsApiResponse,
  IUnitCompleted,
  LessonResponse,
  umbracoApi,
  useGetLessonsByIdQuery,
  useGetLessonsByLessonIdUnitsQuery,
  usePostActivitiesByFeatureIdMutation,
  usePostLessonsByLessonIdUnitsAndIdMutation,
} from "../store/api/umbraco-api";
import { UnitContext } from "./unit-context";

interface LessonContextValue extends UnitContext {
  isLoading: boolean;
  isError: boolean;
  lesson: LessonResponse | undefined;
  units: GetLessonsByLessonIdUnitsApiResponse;
  currentUnitId: number | null;
  setCurrentUnitId: (id: number | null) => void;
}

export const LessonContext = createContext<LessonContextValue | null>(null);

interface LessonProviderProps {
  lessonId: number;
}

export const LessonProvider = ({
  lessonId,
  children,
}: PropsWithChildren<LessonProviderProps>) => {
  // Fetch lesson and units data
  const lessonState = useGetLessonsByIdQuery({ id: lessonId });
  const unitsState = useGetLessonsByLessonIdUnitsQuery({ lessonId });

  const [fetchUnitContent, unitContentState] =
    umbracoApi.useLazyGetLessonsByLessonIdUnitsAndUnitIdQuery();
  const [postUnitCompletion, unitCompletionState] =
    usePostLessonsByLessonIdUnitsAndIdMutation();

  // unit activity post request
  const [postActivity, activityState] = usePostActivitiesByFeatureIdMutation();

  const [currentUnitId, setCurrentUnitId] = useState<number | null>(
    parseInt(localStorage.getItem(`lesson:${lessonId}:currentUnitId`) || "0") || null
  );

  // fetch unit content when unitId is changed
  useEffect(() => {
    if (currentUnitId) {

      if (!activityState.isLoading && unitContentState.fulfilledTimeStamp) {
        postActivity({
          featureId: lessonId,
          createUnitActivityRequest: {
            unitId: currentUnitId,
            startTime: new Date(unitContentState.fulfilledTimeStamp).toISOString(),
            endTime: new Date().toISOString()
          }
        }).then(() => {
          activityState.reset();
        })
      }

      fetchUnitContent({ lessonId, unitId: currentUnitId });
      localStorage.setItem(`lesson:${lessonId}:currentUnitId`, currentUnitId.toString());
      unitCompletionState.reset();
    }
  }, [currentUnitId]);

  const handlePostUnitCompletion = (completion: IUnitCompleted) => {
    postUnitCompletion({
      lessonId,
      id: completion.id || 0,
      iUnitCompleted: completion,
    })
      .unwrap()
      .then(() => {
        lessonState.refetch();
        unitsState.refetch();
      })
      .catch(() => {
        console.error("Erro while posting unit completion: ", completion.id);
      });
  };

  return (
    <LessonContext.Provider
      value={{
        isLoading: lessonState.isLoading || unitsState.isLoading,
        isError: lessonState.isError || unitsState.isError,
        lesson: lessonState.data,
        units: unitsState.data || [],
        currentUnitId,
        setCurrentUnitId,
        unitContent: {
          isError: unitContentState.isError,
          isLoading:
            unitContentState.isUninitialized || unitContentState.isFetching,
          data:
            unitContentState.isFetching === false
              ? {
                  ...unitContentState.currentData,
                  isChat: (unitContentState.currentData?.unit as any)?.isChat,
                  isRevealSolution: lessonState.data?.isRevealSolution || false,
                }
              : undefined,
        },
        unitCompletion: {
          isLoading: unitCompletionState.isLoading,
          response: unitCompletionState.data,
          post: handlePostUnitCompletion,
        },
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};
