import { UnitTypes } from "./types";
import AIConversation from "./ai-conversation";
import ContentUnit from "./content-unit";
import { UnitProps } from "./interfaces";
import MultiChoiseExreciseUnit from "./multi-choise-exrecise";
import OpenExerciseQuestion from "./open-exercise-question";
import { BoxProps } from "@mui/joy";
import { Box } from "@mui/joy";

interface UnitSwitcherProps extends UnitProps, BoxProps {
  contentRef?: React.RefObject<HTMLDivElement>;
}

export default function UnitSwitcher({ contentRef, ...props }: UnitSwitcherProps) {
  
  const renderUnit = () => {
    switch (props.unitType as unknown as UnitTypes) {
      case UnitTypes.ContentUnit:
        return <ContentUnit {...props} />;
      case UnitTypes.OpenResponseExercise:
        return <OpenExerciseQuestion {...props} />;
      case UnitTypes.MultiChoiceExercise:
        return <MultiChoiseExreciseUnit {...props} />;
      case UnitTypes.AIConversation:
        return <AIConversation {...props} contentRef={contentRef} />;
      default:
        return <div>No matching component</div>;
    }
  };

  return <Box>{renderUnit()}</Box>;
}
