import { Box, RadioGroup, Typography, FormLabel, Radio } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { useReward } from "react-rewards";
import { IUnit, IUnitCompleted } from "../../store/api/umbraco-api";
import { UnitProps } from "./interfaces";
import Error from "./shared/error";
import Loading from "./shared/loading";
import useElapsedTime from "../../hooks/use-elapsed-time";
import RichText from "../rich-text";
import { UnitContentResponse } from "../../context/unit-context";
import useUnit from "../../context/use-unit";
import { Cancel, CheckCircle, Send } from "@mui/icons-material";
import { animated, useSpringValue } from "@react-spring/web";

import { PrimaryActionButton } from "./shared/btn";
import { palette } from "../../theme";

enum QuestionState {
  ANSWERING,
  WRONG_ANSWER,
  CORRECT_ANSWER,
}

interface MultiChoiceContentResponse extends UnitContentResponse {
  unit: IUnit & {
    id: number;
    title: string;
    answers: string[];
    correctAnswer?: number;
    solution?: string | null;
    question: string;
  };
  completion: IUnitCompleted & { answerIndex: number };
}

const MultiChoiceExercise = ({ unitId }: UnitProps) => {
  const { unitContent, unitCompletion } = useUnit<MultiChoiceContentResponse>();
  const fetchTime = useElapsedTime();

  const [chosen, setChosen] = useState<number | null>(null);
  const [questionState, setQuestionState] = useState<QuestionState>(
    QuestionState.ANSWERING
  );

  const x = useSpringValue(0, {
    config: {
      mass: 0.5,
      friction: 2,
    },
  });

  const { reward } = useReward("rewardId", "emoji", {
    emoji: ["💯", "🙌", "🥳", "🪅"],
  });

  const isAnswered = useMemo(
    () => questionState !== QuestionState.ANSWERING,
    [questionState]
  );

  useEffect(() => {
    if (unitContent.data?.unit.id === unitId && !unitContent.isLoading) {
      const { unit, isCompleted, completion } = unitContent.data;
      if (isCompleted) {
        setChosen(completion.answerIndex);
        setQuestionState(
          completion.answerIndex === unit.correctAnswer
            ? QuestionState.CORRECT_ANSWER
            : QuestionState.WRONG_ANSWER
        );
      }
    }
  }, [unitContent.data, unitContent.isLoading]);

  if (!unitContent.data || unitContent.isLoading) {
    return <Loading />;
  }

  if (unitContent.isError) {
    return <Error />;
  }

  const { unit } = unitContent.data;

  const onAnswer = async () => {
    if (chosen === null) return;

    const correct = chosen === unit?.correctAnswer;
    setQuestionState(
      correct ? QuestionState.CORRECT_ANSWER : QuestionState.WRONG_ANSWER
    );

    if (correct) {
      reward();
    } else {
      x.start({ from: 10, to: 0 });
    }

    // Post completion data
    try {
      unitCompletion.post({
        id: unitId,
        type: unit.type,
        start: fetchTime.toISOString(),
        end: new Date().toISOString(),
        answerIndex: chosen,
      } as IUnitCompleted);
    } catch (err) {
      console.error("Error posting unit data:", err);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {unit.title && (
        <Typography level="h4" sx={{ fontWeight: "bold" }}>
          {unit.title}
        </Typography>
      )}

      {/* Question Text */}
      <RichText content={unit.question} />

      {/* Answers */}
      <animated.div style={{ x }}>
        <RadioGroup name="answers" sx={{ gap: 1 }}>
          {unit.answers &&
            unit.answers.map((ans, index) => (
              <span key={index}>
                <FormLabel>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Radio
                      value={index}
                      checked={chosen === index}
                      onChange={() => setChosen(index)}
                      disabled={isAnswered}
                    />
                    <RichText content={ans} />
                  </Box>
                </FormLabel>
              </span>
            ))}
        </RadioGroup>
      </animated.div>

      {/* Feedback Section */}
      {isAnswered && (
        <Box
          sx={{
            p: 2,
            border:
              questionState === QuestionState.CORRECT_ANSWER
                ? `1px solid ${palette.green_finish}`
                : `1px solid ${palette.red}`,
            borderRadius: "12px",
          }}
        >
          <Typography
            sx={{
              color:
                questionState === QuestionState.CORRECT_ANSWER
                  ? palette.green_finish
                  : palette.red,
            }}
            // variant="h6"
            display="flex"
            alignItems="center"
            gap={1}
          >
            {questionState === QuestionState.CORRECT_ANSWER ? (
              <CheckCircle fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )}
            {questionState === QuestionState.CORRECT_ANSWER
              ? "תשובה נכונה"
              : "הפעם טעית"}
          </Typography>
          {unit.solution && <RichText content={unit.solution} />}
        </Box>
      )}

      {/* Action Buttons */}
      {!isAnswered && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <PrimaryActionButton
            text="שלח תשובה"
            iconSide="left"
            icon={<Send sx={{ fontSize: 14, transform: "rotate(180deg)" }} />}
            onClick={onAnswer}
            disabled={chosen === null}
          />
        </Box>
      )}

      <span style={{ alignSelf: "center" }} id="rewardId" />
    </Box>
  );
};

export default MultiChoiceExercise;
