import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, LinearProgress } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { LessonStatus, LessonUnit } from "../../../../store/api/umbraco-api";
import MobileChat from "../../../../components/chat/side-chat/mobile-chat";
import UnitSwitcher from "../../../../components/units/unit-switcher";
import LessonSidebar from "./lesson-side-bar";
import { getLessonStatus } from "../../../../utils/types";
import { LessonProvider } from "../../../../context/lesson-context";
import useLesson from "../../../../context/use-lesson";
import { UnitTypes } from "../../../../components/units/types";
import NavigationButtons from "../../../../components/units/shared/navigation-btns";
import ResizableChat from "../../../../components/chat/resizable-chat";
import ThreeColumnLayout from "../../../../components/layouts/ThreeColumnLayout";

const LessonScreen = () => {
  const {
    isLoading,
    isError,
    lesson: lessonData,
    units: unitsData,
    currentUnitId,
    setCurrentUnitId,
    unitContent,
  } = useLesson();

  const isMobile = useMediaQuery("(max-width:1000px)");

  const lessonStatus: LessonStatus = getLessonStatus(
    typeof lessonData?.status === "number" ? lessonData.status : 2
  );

  const selectedUnit = useMemo(() => {
    return unitsData.find((u) => u.id === currentUnitId);
  }, [currentUnitId, unitsData]);

  const selectedUnitIndex = useMemo(() => {
    return unitsData.findIndex((u) => u.id === currentUnitId);
  }, [currentUnitId, unitsData]);

  const handleNext = () => {
    if (unitsData && selectedUnitIndex < unitsData.length - 1) {
      const id = unitsData[selectedUnitIndex + 1].id;
      if (id) setCurrentUnitId(id);
    }
  };

  const handleBack = () => {
    if (selectedUnitIndex > 0) {
      const id = unitsData[selectedUnitIndex - 1].id;
      if (id) setCurrentUnitId(id);
    }
  };

  useEffect(() => {
    if (selectedUnitIndex < 0 && unitsData && unitsData.length > 0) {
      const firstIncomplete = unitsData.find((unit) => !unit.isComplete);
      if (firstIncomplete && firstIncomplete.id! > 0) {
        setCurrentUnitId(firstIncomplete.id!);
      } else {
        setCurrentUnitId(unitsData[0].id || null);
      }
    }
  }, [unitsData]);

  const isChat = useMemo(
    () =>
      selectedUnit &&
      unitContent.data?.isChat &&
      (selectedUnit.type as unknown as UnitTypes) !== UnitTypes.AIConversation,
    [selectedUnit, unitContent]
  );

  const chatPanel =
    isChat &&
    (isMobile ? (
      <MobileChat resourceId={selectedUnit?.id || 0} />
    ) : (
      <ResizableChat resourceId={unitContent.data?.unit?.id || 0} />
    ));

  const contentRef = useRef<HTMLDivElement>(null);

  if (isLoading) return <LinearProgress />;
  if (isError) return <div>Error loading lesson or units</div>;

  return (
    <ThreeColumnLayout
      sidebar={
        <LessonSidebar
          name={lessonData?.name || ""}
          description={lessonData?.description || ""}
          status={lessonStatus}
          setSelectedUnit={(unit: LessonUnit) =>
            setCurrentUnitId(unit?.id || null)
          }
          totalUnits={lessonData?.totalUnits || 0}
          units={unitsData}
          completedUnits={lessonData?.completedUnits || 0}
          isMobile={isMobile}
          selectedUnit={selectedUnit || unitsData[0]}
        />
      }
      main={
        <Box
          ref={contentRef}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
          data-testid="lesson-content"
        >
          {selectedUnit?.type !== undefined &&
            (unitContent.isLoading ? (
              <LinearProgress />
            ) : (
              <>
                <Box sx={{ ...styles.unitSwitcher, overflow: "visible" }}>
                  <UnitSwitcher
                    contentRef={contentRef}
                    unitId={selectedUnit.id!}
                    unitType={selectedUnit.type}
                  />
                </Box>
                <NavigationButtons
                  onBack={handleBack}
                  onNext={handleNext}
                  disableBack={selectedUnitIndex === 0}
                  disableNext={selectedUnitIndex === unitsData.length - 1}
                />
              </>
            ))}
        </Box>
      }
      rightPanel={chatPanel}
      isMobile={isMobile}
    />
  );
};

const LessonScreenWithContext = () => {
  const { featureId } = useParams();
  const lessonId = featureId ? parseInt(featureId) : 0;

  return (
    <LessonProvider lessonId={lessonId}>
      <LessonScreen />
    </LessonProvider>
  );
};

export default LessonScreenWithContext;

const styles = {
  unitSwitcher: {
    padding: 2,
    borderRadius: 20,
    flex: 1,
  },
};
