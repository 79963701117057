import { Box, BoxProps } from "@mui/joy";




interface LogoBoxProps {
  boxProps?: BoxProps;
  imageHeight?: number;
  imageSrc?: string; // Add this prop to pass the image source
}

const LogoBox = ({
  boxProps,
  imageHeight = 60,
  imageSrc, // Set a default value if imageSrc is not passed
}: LogoBoxProps) => (
  <Box {...boxProps}>
    <img height={imageHeight} src={imageSrc} alt="Logo" />
  </Box>
);

export default LogoBox;
