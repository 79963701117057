import {
  Gender,
  useGetLoginProvidersByProviderUrlNameQuery,
  usePostAuthSignupMutation,
} from "../store/api/umbraco-api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Box, Input } from "@mui/joy";
import LogoBox from "../components/header/logo-box";
import { TAUGA_AI_LOGO_1 } from "../images/images";
import useNavigateWithProvider from "../hooks/use-navigate-with-provider";
import { palette } from "../theme";
import { Select, MenuItem } from "@mui/material";
import LoginImage from "../images/Login_Image.png";
import SubmitButton from "../components/buttons";
import { setProvider } from "../store/provider-slice";
import { useDispatch } from "react-redux";

const Signup = () => {
  const navigateWithProvider = useNavigateWithProvider();

  const [mutate, { isLoading }] = usePostAuthSignupMutation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState(1);

  const { provider } = useParams();
  const dispatch = useDispatch();

  const { data: loginProvider } = useGetLoginProvidersByProviderUrlNameQuery({
    providerUrlName: provider || "",
  });

  useEffect(() => {
    if (provider) {
      dispatch(setProvider(provider));
    }
  }, [provider]);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const genderMap: { [key: number]: Gender } = {
    0: "Other",
    1: "Male",
    2: "Female",
  };

  const genderValue = genderMap[gender];

  const onSignup = () => {
    setValidationErrors({});
    let errors: { [key: string]: string } = {};

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      errors.email = "אנא הזן כתובת מייל תקינה.";
    }
    if (!password.match(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      errors.password =
        "סיסמא צריכה להיות באורך 8 תווים ולכלול אותיות ומספרים.";
    }
    if (!firstName || firstName.length > 50) {
      errors.firstName = "שם פרטי נדרש ואסור לעלות על 50 תווים.";
    }
    if (!lastName || lastName.length > 50) {
      errors.lastName = "שם משפחה נדרש ואסור לעלות על 50 תווים.";
    }
    if (
      yearOfBirth &&
      (parseInt(yearOfBirth) < 1900 ||
        parseInt(yearOfBirth) > new Date().getFullYear())
    ) {
      errors.yearOfBirth = "שנת לידה חייבת להיות בין 1900 לשנה הנוכחית.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    mutate({
      signupRequest: {
        signupProvider: provider,
        email,
        password,
        firstName,
        lastName,
        yearOfBirth: yearOfBirth ? parseInt(yearOfBirth) : undefined,
        phoneNumber: phoneNumber || undefined,
        gender: genderValue,
      },
    }).then((response) => {
      if (response.data) {
        navigateWithProvider("/auth/login");
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: palette.purple_light,
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      {/* Left: Signup Card */}
      <Box
        sx={{
          backgroundColor: palette.white,
          borderWidth: 1,
          borderColor: palette.gray_light,
          borderStyle: "solid",
          height: { md: "90%" },
          overflow: "scroll",
          padding: { xs: 1, md: 4 },
          borderBottomLeftRadius: 50,
          borderBottomRightRadius: 50,
          width: { xs: "100%", lg: "30%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 2,
              marginY: 4,
            }}
          >
            {loginProvider?.logoUrl?.map((logo, index) => (
              <Box
                key={index}
                component="img"
                src={logo}
                alt={`Login Provider Logo ${index}`}
                sx={{
                  width: { xs: 40, md: 60 },
                  height: { xs: 40, md: 60 },
                  borderRadius: 4,
                  objectFit: "cover", // Maintain logo aspect ratio
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            ))}
          </Box>
          <LogoBox imageSrc={TAUGA_AI_LOGO_1} imageHeight={60} />
          <Typography level="h1" sx={{ marginTop: 2, fontWeight: "bold" }}>
            ברוכים הבאים לטאוגה
          </Typography>
          <Typography
            level="body-lg"
            sx={{
              marginTop: 1,
              color: "gray",
              maxWidth: 400,
              lineHeight: 1.5,
            }}
          >
            הירשם כדי להתחיל ללמוד קורסים שונים ולהתקדם{" "}
            <Typography
              component="span"
              sx={{
                color: palette.purple,
                fontWeight: "bold",
              }}
            >
              בקצב שלך!
            </Typography>
          </Typography>
        </Box>

        {/* Input Fields */}
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="מייל"
            // error={!!validationErrors.email}
            // helperText={validationErrors.email}
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="סיסמא"
            // error={!!validationErrors.password}
            // helperText={validationErrors.password}
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="שם פרטי"
            // error={!!validationErrors.firstName}
            // helperText={validationErrors.firstName}
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="שם משפחה"
            // error={!!validationErrors.lastName}
            // helperText={validationErrors.lastName}
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
          <Input
            value={yearOfBirth}
            onChange={(e) => setYearOfBirth(e.target.value)}
            placeholder="שנת לידה"
            type="number"
            // error={!!validationErrors.yearOfBirth}
            // helperText={validationErrors.yearOfBirth}
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
          <Input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="טלפון (אופציונלי)"
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important", // Override default
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none", // Removes border from 'outlined' variant
              },
            }}
          />
        </Box>

        {/* Gender Select */}
        <Select
          value={gender}
          onChange={(e) => setGender(Number(e.target.value))}
          variant="outlined"
          sx={{
            backgroundColor:
              palette.gray_light,
            width: "100%",
            maxWidth: 400,
            height: 50,
            border: "none !important", // Override default
            borderRadius: 1,
            fontSize: "1rem",
            "& fieldset": {
              border: "none", // Removes border from 'outlined' variant
            },
          }}
        >
          <MenuItem value={0}>אחר</MenuItem>
          <MenuItem value={1}>זכר</MenuItem>
          <MenuItem value={2}>נקבה</MenuItem>
        </Select>

        {/* Signup Button */}
        <SubmitButton
          disabled={
            !email ||
            !password ||
            !firstName ||
            !lastName ||
            !yearOfBirth ||
            !phoneNumber ||
            !gender
          }
          text="הרשמה"
          loadingText="נרשם..."
          isLoading={isLoading}
          onClick={onSignup}
        />

        <Typography
          textAlign="center"
          sx={{
            marginTop: 1,
            fontSize: "0.9rem",
            color: "gray",
            cursor: "pointer",
          }}
        >
          רשום כבר?{" "}
          <Typography
            component="span"
            sx={{
              color: palette.purple,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            onClick={() => navigateWithProvider("/login")}
          >
            התחבר
          </Typography>
        </Typography>
      </Box>
      <Box
        component="img"
        src={LoginImage}
        alt="Login illustration"
        sx={{
          justifySelf: "center",
          alignSelf: "center",
          objectFit: "cover",
          display: { xs: "none", lg: "block" },
        }}
      />
    </Box>
  );
};

export default Signup;
