import { Box, Typography, List, ListItem, Container } from "@mui/joy";
import { palette } from "../../theme";

const AccessibilityDeclaration = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 6 }}>
        <Typography
          level="h1"
          sx={{
            mb: 5,
            fontSize: { xs: "2rem", md: "2.5rem" },
            textAlign: "center",
          }}
        >
          הצהרת נגישות
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Box>
            <Typography sx={{ mb: 3, lineHeight: 1.7 }}>
              TAUGA AI, אחראית על הקמת והפעלת אתר{" "}
              <Typography
                component="a"
                href="https://www.tauga.ai"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: palette.black,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                www.tauga.ai
              </Typography>
              . אנו רואים חשיבות רבה במתן שירות שוויוני לכלל האזרחים ובשיפור
              השירות הניתן לאזרחים עם מוגבלות. אנו משקיעים משאבים רבים בהנגשת
              האתר והנכסים הדיגיטליים שלנו על מנת להפוך את שירותי החברה לזמינים
              יותר עבור אנשים עם מוגבלות.
            </Typography>
            <Typography sx={{ lineHeight: 1.7 }}>
              במדינת ישראל כ-20 אחוזים מקרב האוכלוסייה הינם אנשים עם מוגבלות
              הזקוקים לנגישות דיגיטלית, על מנת לצרוך מידע ושירותים כללים. הנגשת
              האתר של TAUGA AI, נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש
              עבור אוכלוסיות עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות
              מוטוריות שונות, לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון
              צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית על בני הגיל השלישי.
            </Typography>
          </Box>

          <Box>
            <Typography
              level="h2"
              sx={{
                mb: 3,
                fontSize: { xs: "1.5rem", md: "1.75rem" },
                color: palette.black,
              }}
            >
              מידע על הנגשת האתר
            </Typography>
            <List
              sx={{
                "--List-gap": "1rem",
                "--ListItem-paddingY": "0.5rem",
              }}
            >
              <ListItem>
                הנגשת אתר זה בוצעה על ידי חברת הנגשת האתרים "Vee הנגשת אתרים".
              </ListItem>
              <ListItem>רמת הנגישות באתר - AA</ListItem>
              <ListItem>
                חברת "Vee", התאימה את נגישות האתר לדפדפנים הנפוצים ולשימוש
                בטלפון הסלולרי ככל הניתן, והשתמשה בבדיקותיה בקוראי מסך מסוג Jaws
                ו- NVDA.
              </ListItem>
              <ListItem>
                מקפידה על עמידה בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות
                5568 התשע"ג 2013 ברמת AA. וכן, מיישמת את המלצות מסמך WCAG2.2 מאת
                ארגון W3C{" "}
                <Typography
                  component="a"
                  href="https://www.w3.org/TR/WCAG22/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: palette.black,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  למידע נוסף
                </Typography>
              </ListItem>
              <ListItem>
                בעברית: הנחיות לנגישות תכנים באינטרנט באנגלית: Web Content
                Accessibility Guidelines (WCAG) 2.0{" "}
                <Typography
                  component="a"
                  href="https://www.isoc.org.il/files/w3c-wai/guidelines.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: palette.black,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  למידע נוסף
                </Typography>
              </ListItem>
              <ListItem>
                הנגשת האתר בוצעה בהתאם להנחיות רשות התקשוב להנגשת יישומים
                בדפדפני אינטרנט{" "}
                <Typography
                  component="a"
                  href="https://www.gov.il/he/departments/policies/web_accessibility_guidelines"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: palette.black,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  למידע נוסף
                </Typography>
              </ListItem>
            </List>
          </Box>

          <Box>
            <Typography level="h2" sx={{ mb: 3, color: palette.black }}>
              כיצד עוברים למצב נגיש?
            </Typography>
            <Typography sx={{ mb: 3, lineHeight: 1.7 }}>
              באתר מוצב אייקון נגישות (בד"כ בדפנות האתר). לחיצה על האייקון
              מאפשרת פתיחת של תפריט הנגישות. לאחר בחירת הפונקציה המתאימה בתפריט
              יש להמתין לטעינת הדף ולשינוי הרצוי בתצוגה (במידת הצורך).
            </Typography>
            <Typography sx={{ mb: 3, lineHeight: 1.7 }}>
              במידה ומעוניינים לבטל את הפעולה, יש ללחוץ על הפונקציה בתפריט פעם
              שניה. בכל מצב, ניתן לאפס הגדרות נגישות.
            </Typography>
            <Typography sx={{ mb: 3, lineHeight: 1.7 }}>
              התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera
              בכפוף (תנאי יצרן) הגלישה במצב נגישות מומלצת בדפדפן כרום.
            </Typography>
            <Typography sx={{ mb: 3, lineHeight: 1.7 }}>
              האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש
              המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה
              מתפריטים וחלונות.
            </Typography>
            <Typography sx={{ lineHeight: 1.7 }}>
              לצורך קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים
              לשימוש בתוכנת NVDA העדכנית ביותר.
            </Typography>
          </Box>

          <Box>
            <Typography level="h2" sx={{ mb: 2 }}>
              תיקונים והתאמות שבוצעו באתר
            </Typography>
            <List>
              <ListItem>התאמה לקוראי מסך - NVDA, JAWS</ListItem>
              <ListItem>עצירת הבהובים ואנימציות</ListItem>
              <ListItem>דילוג ישיר לתוכן</ListItem>
              <ListItem>התאמה לניווט מקלדת</ListItem>
              <ListItem>הגדלה / הקטנה של טקסט</ListItem>
              <ListItem>ריווח בין אותיות / מילים / שורות</ListItem>
              <ListItem>שינויי ניגודיות וצבעים</ListItem>
              <ListItem>שימוש בגופן קריא</ListItem>
              <ListItem>הדגשת קישורים</ListItem>
              <ListItem>שינוי סמן עכבר</ListItem>
              <ListItem>תיאור טקסטואלי חלופי (alt) לכל התמונות</ListItem>
            </List>
          </Box>

          <Box>
            <Typography level="h2" sx={{ mb: 2 }}>
              יצירת קשר בנושא נגישות
            </Typography>
            <Typography sx={{ mb: 2 }}>
              במידה ונתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות
              באמצעות פנייה לרכז הנגישות שלנו. נא לצרף את הפרטים הבאים:
            </Typography>
            <List>
              <ListItem>תיאור הבעיה</ListItem>
              <ListItem>מהי הפעולה שניסיתם לבצע</ListItem>
              <ListItem>קישור לדף שבו גלשתם</ListItem>
              <ListItem>סוג הדפדפן וגרסתו</ListItem>
              <ListItem>מערכת הפעלה</ListItem>
              <ListItem>סוג הטכנולוגיה המסייעת (אם רלוונטי)</ListItem>
            </List>

            <Box
              sx={{
                bgcolor: "background.level1",
                p: 4,
                borderRadius: "md",
                boxShadow: "md",
              }}
            >
              <Typography level="h3" sx={{ mb: 2, color: palette.black }}>
                רכז נגישות:
              </Typography>
              <Typography sx={{ mb: 1 }}>עידן בן דוד</Typography>
              <Typography sx={{ mb: 1 }}>0549446116</Typography>
              <Typography
                component="a"
                href="mailto:Idan@tauga.ai"
                sx={{
                  color: palette.black,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Idan@tauga.ai
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 6,
              pt: 3,
              borderTop: "1px solid",
              borderColor: "divider",
              textAlign: "center",
            }}
          >
            <Typography level="body-sm" textColor="neutral.500">
              תאריך עדכון הצהרת נגישות 26-02-2025
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AccessibilityDeclaration;
