import { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";

import { ASSETS } from "../../../consts";
import { palette } from "../../../theme";
import { usePostChatMutation } from "../../../store/api/umbraco-api";
import { userSelector } from "../../../store/session/selectors";
import { useSelector } from "react-redux";
import { ChatInput } from "../chat-input";
import { MessagesList } from "../messages-list";
import { streamMessage } from '../../../services/openai';

interface ChatProps {
  resourceId: number;
}

interface Message {
  sender: string;
  text: string;
}

const Chat = ({ resourceId }: ChatProps) => {
  const [input, setInput] = useState<string>("");
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [isBotTyping, setIsBotTyping] = useState<boolean>(false);
  const user = useSelector(userSelector);
  const [streamingMessage, setStreamingMessage] = useState<string>("");
  const [isStreaming, setIsStreaming] = useState(false);

  const [messages, setMessages] = useState<Array<Message>>([
    { sender: "AI", text: `היי ${user?.firstName}, במה אני יכול לעזור לך?` },
  ]);

  const [postChat] = usePostChatMutation();

  const handleStream = (conversationId: string, message: string) => {
    let fullMessage = '';
    return streamMessage({
      conversationId,
      message
    },
    chunk => {
      fullMessage += chunk;
      setStreamingMessage(fullMessage);
    },
    () => {
      setMessages(prev => [...prev, { sender: "AI", text: fullMessage }]);
      setStreamingMessage("");
      setIsStreaming(false);
      setIsBotTyping(false);  
    });
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;
    setIsBotTyping(true);

    const userMessage = { sender: "User", text: input };
    const currentInput = input;
    setMessages(prev => [...prev, userMessage]);
    setInput("");
    setIsBotTyping(true);

    try {
      if (!conversationId) {
        // Initial message - create conversation with fetch
        const response = await postChat({createConversationRequest: { 
          initialMessage: currentInput,
          resourceId 
        }}).unwrap();
        
        setConversationId(response.conversationId || null);
        setMessages(prev => [
          ...prev,
          { sender: "AI", text: response.message || "ברוך הבא לשיחה!" },
        ]);
        setIsBotTyping(false);
      } else {
        setIsStreaming(true);
        setStreamingMessage("");
        await handleStream(conversationId, currentInput);
      }
    } catch (err) {
      console.error("Error in message sending:", err);
      setIsBotTyping(false);
      setIsStreaming(false);
    }
  };

  const handleStartOver = () => {
    setMessages([
      { sender: "AI", text: `היי ${user?.firstName}, במה אני יכול לעזור לך?` },
    ]);
    setConversationId(null);
    setIsBotTyping(false);
  };

  useEffect(() => {
    if (conversationId !== null) handleStartOver();
  }, [resourceId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: palette.white,
        borderRadius: "16px",
        borderWidth: 1,
        borderColor: palette.gray_light,
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      {/* Header with improved styling */}
      <Box
        sx={{
          padding: "16px 20px",
          borderBottom: `1px solid ${palette.gray_light}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backdropFilter: "blur(8px)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              position: "relative",
              width: 48,
              height: 48,
            }}
          >
            <img
              src={ASSETS.AI_CHAT_IMAGE}
              alt="NEURONS"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                objectFit: "cover",
                border: `2px solid ${palette.purple_light}`,
              }}
            />
            {/* Online indicator */}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: "#4CAF50",
                border: "2px solid white",
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: "1.1rem",
                color: palette.gray,
                marginBottom: "2px",
              }}
            >
              טאוגה
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: palette.gray,
                fontSize: "0.85rem",
                opacity: 0.8,
              }}
            >
              היעזר בטאוגה, עוזרת ההוראה מבינה מלאכותית
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={handleStartOver}
          sx={{
            color: palette.purple,
            backgroundColor: palette.purple_light,
            opacity: 0.8,
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: palette.purple_light,
              opacity: 1,
              transform: "scale(1.05)",
            },
          }}
        >
          <RestartAlt />
        </IconButton>
      </Box>

      {/* Messages area */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          padding: "16px",
          "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: palette.gray_light,
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.gray,
            },
          },
        }}
      >
        <MessagesList
          messages={messages}
          isBotTyping={isBotTyping}
          streamingMessage={streamingMessage}
          isStreaming={isStreaming}
        />
      </Box>

      {/* Chat input with improved styling */}
      <Box
        sx={{
          borderTop: `1px solid ${palette.gray_light}`,
          padding: "16px",
          backgroundColor: palette.white,
        }}
      >
        <ChatInput
          input={input}
          setInput={setInput}
          handleSendMessage={handleSendMessage}
        />
      </Box>
    </Box>
  );
};

export default Chat;
