import { Outlet } from "react-router-dom";
import { userSelector } from "../../store/session/selectors";
import { useEffect } from "react";
import useNavigateWithProvider from "../../hooks/use-navigate-with-provider";
import { Box } from "@mui/joy";
import { useSelector } from "react-redux";

const UnauthenticatedRoute = () => {
  const user = useSelector(userSelector);

  const navigateWithProvider = useNavigateWithProvider();
  useEffect(() => {
    if (user) {
      navigateWithProvider("/");
    }
  }, [user]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Outlet />
    </Box>
  );
};

export default UnauthenticatedRoute;
