import { Box, LinearProgress, Typography } from "@mui/joy";
import TeacherImage from "../../images/Teacher.png";
import { AccessTime, DoneAll, Whatshot } from "@mui/icons-material";
import Metric from "../../components/home_screen/metric";
import ChapterCard from "../../components/home_screen/chapter-card";
import { palette } from "../../theme";
import {
  Feature,
  useGetCoursesByCourseIdChaptersQuery,
  useGetCoursesByIdQuery,
} from "../../store/api/umbraco-api";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type NumericFeatureType = number;

// Update the Feature interface to use the numeric type
export interface FeatureWithNumericType extends Omit<Feature, "type"> {
  type?: NumericFeatureType;
}

const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box sx={{ mb: 4 }}>
    <Typography level="h3" sx={{ mb: 2 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

const CourseScreen = () => {
  const selectedCourse = useSelector(
    (state: RootState) => state.selectedCourse
  );

  const { data: course, isLoading } = useGetCoursesByIdQuery(
    { id: selectedCourse.id || 0 },
    { skip: !selectedCourse.id }
  );

  const { data: chapters = [], isLoading: isChaptersLoading } =
    useGetCoursesByCourseIdChaptersQuery(
      { courseId: selectedCourse.id || 0 },
      { skip: !selectedCourse.id }
    );

  const completedChaptersCount = chapters.filter(
    (chapter) =>
      (chapter.completed || 0) === (chapter.total || 0) &&
      (chapter.total || 0) > 0
  ).length;

  const inProgressChaptersCount = chapters.filter(
    (chapter) =>
      (chapter.completed || 0) > 0 &&
      (chapter.completed || 0) < (chapter.total || 0)
  ).length;

  if (isLoading || isChaptersLoading) {
    return <LinearProgress sx={{ width: "600px" }} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        paddingTop: { xs: 2, md: 4 },
        height: "100%",  // Take full height of parent
        overflow: "auto", // Enable scrolling
        padding: { xs: 2, md: 4 }, // Add padding on all sides
      }}
    >
      <Box
        sx={{
          display: { xs: "grid", md: "flex" }, // Use grid on smaller screens, flex on larger screens
          gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" }, // Single column for very small screens, two columns for small screens
          alignItems: "center",
          justifyContent: "space-between", // Spacing in flex mode
          gap: 2, // Spacing between grid items
        }}
      >
        <Box sx={{ display: "grid", gap: 2, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Spacing in flex mode
            }}
          >
            <Typography
              sx={{
                alignSelf: "center",
              }}
              level="h1"
            >
              {course?.name}
            </Typography>
            <Box
              component="img"
              src={TeacherImage}
              alt="logo"
              sx={{
                maxHeight: { xs: 200, sm: 300, xl: 350 }, // Scale the max height for different screen sizes
                width: { xs: "40%", sm: "40%" }, // Full width for small screens, auto for larger screens
                display: { md: "none" }, // Hide on small screens, show on medium and larger screens
              }}
            />
          </Box>

          <Box
            sx={{
              display: "grid", // Grid layout for metrics
              gap: 1,
              gridTemplateColumns: {
                sm: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)",
              }, // Adjust columns based on screen size
            }}
          >
            <Metric
              mainColor={palette.purple}
              bgcolor={palette.purple_light}
              label="פרקים שהושלמו"
              value={completedChaptersCount}
              icon={
                <DoneAll
                  sx={{
                    fontSize: "36px",
                    color: palette.purple,
                  }}
                />
              }
            />
            <Metric
              mainColor={palette.yellow}
              label="פרקים בתהליך"
              value={inProgressChaptersCount}
              bgcolor={palette.yellow_light}
              icon={
                <AccessTime
                  sx={{
                    fontSize: "36px",
                    color: palette.yellow,
                  }}
                />
              }
            />
            <Metric
              mainColor={palette.orange}
              bgcolor={palette.orange_light}
              label="סטריק יומי"
              value={course?.dailyStreak || 0}
              icon={
                <Whatshot
                  sx={{
                    fontSize: "36px",
                    color: palette.orange,
                  }}
                />
              }
            />
          </Box>
        </Box>

        <Box
          component="img"
          src={TeacherImage}
          alt="logo"
          sx={{
            maxHeight: { xs: 200, sm: 300, xl: 350 }, // Scale the max height for different screen sizes
            width: { sm: "40%", md: "30%", lg: "40%" }, // Full width for small screens, auto for larger screens
            objectFit: "contain", // Maintain aspect ratio and prevent cropping
            alignSelf: { xs: "center", md: "flex-end" }, // Center on small screens, align to the right on larger screens
            display: { xs: "none", md: "block" }, // Hide on small screens, show on medium and larger screens
          }}
        />
      </Box>

      <Section title="פרקי למידה">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            },
            gap: 2,
          }}
        >
          {chapters &&
            chapters.map((chapter, index) => {
              return (
                <ChapterCard
                  courseId={selectedCourse.id || 0}
                  id={chapter.id || 0}
                  key={index}
                  title={chapter.title}
                  total={chapter.total}
                  completed={chapter.completed}
                  learningMinutes={chapter.learningMinutes}
                  features={
                    chapter.features as unknown as FeatureWithNumericType[]
                  } // Add type assertion here
                />
              );
            })}
        </Box>
      </Section>
    </Box>
  );
};

export default CourseScreen;
