import { useContext } from "react";
import { LessonContext } from "./lesson-context";

const useLesson = () => { 
    const context = useContext(LessonContext);
    if (context === null) {
        throw new Error("Cant use lesson context outside of lesson context provider");
    }

    return context;
};

export default useLesson;