import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ExerciseResponse,
  IUnit,
  IUnitCompleted,
  umbracoApi,
  useGetExercisesByExerciseIdAnswersQuery,
  useGetExercisesByExerciseIdUnitsCurrentQuery,
  useGetExercisesByIdQuery,
  usePostActivitiesByFeatureIdMutation,
  usePostExercisesByExerciseIdUnitsAndIdMutation,
  usePostExercisesByExerciseIdUnitsAndIdSkipMutation,
} from "../store/api/umbraco-api";
import { UnitContentResponse, UnitContext } from "./unit-context";

interface ExerciseContextValue extends UnitContext {
  isLoading: boolean;
  isError: boolean;
  exercise: ExerciseResponse | undefined;
  units: {
    id: string;
    unitId: number;
    name: string;
    isCorrect: boolean | undefined;
  }[];
  currentUnit: (IUnit & { title: string }) | null;
  currentIndex: number | null;
  moveForward: () => void;
  moveBack: () => void;
  moveToIndex: (index: number) => void;
  moveNext: () => void;
}

export const ExerciseContext = createContext<ExerciseContextValue | null>(null);

interface ExerciseProviderProps {
  exerciseId: number;
}

export const ExerciseProvider = ({
  exerciseId,
  children,
}: PropsWithChildren<ExerciseProviderProps>) => {
  // define api requests
  const getExerciseQuery = useGetExercisesByIdQuery({ id: exerciseId });
  const getExerciseSidebarQuery = useGetExercisesByExerciseIdAnswersQuery({
    exerciseId,
  });
  const getCurrentUnitQuery = useGetExercisesByExerciseIdUnitsCurrentQuery({
    exerciseId,
  });
  // lazy requests
  const [fetchUnitContent, unitContentState] =
    umbracoApi.useLazyGetExercisesByExerciseIdUnitsAndUnitIdQuery();
  const [postUnitCompletion, unitCompletionState] =
    usePostExercisesByExerciseIdUnitsAndIdMutation();
  const [postSkip, skipState] =
    usePostExercisesByExerciseIdUnitsAndIdSkipMutation();

  const [postActivity, activityState] = usePostActivitiesByFeatureIdMutation();

  // provider private state
  // exercise:
  const [exerciseStatus, setExerciseStatus] = useState<
    "loading" | "error" | "inProgress" | "finished"
  >("loading");
  // unit:
  const [unitStatus, setUnitStatus] = useState<
    "loading" | "error" | "question" | "answer"
  >("loading");
  const [unitIndex, setUnitIndex] = useState<number | null>(null); // null implies the unit is the current unit

  // * Derived state
  const exercise = useMemo(() => getExerciseQuery.data, [getExerciseQuery]);
  const units = useMemo(
    () =>
      getExerciseSidebarQuery.data?.map((unit) => {
        const status = unit.status as unknown as number;
        const isCorrect =
          status === 1 ? true : status === 2 ? false : undefined;
        return {
          id: unit.id || "",
          unitId: unit.unitId || 0,
          name: unit.name || "",
          isCorrect,
        };
      }) || [],
    [getExerciseSidebarQuery]
  );

  const unitContent = useMemo<UnitContentResponse>(() => {
    if (unitIndex !== null) {
      return {
        ...unitContentState.data,
        isChat: false,
        isRevealSolution: exercise?.isRevealSolution || false,
      };
    } else {
      return {
        unit: getCurrentUnitQuery.data as IUnit,
        isCompleted: unitCompletionState.isSuccess,
        completion: unitCompletionState.isSuccess
          ? unitCompletionState.originalArgs?.iUnitCompleted
          : undefined,
        isChat: false,
        isRevealSolution: exercise?.isRevealSolution || false,
      };
    }
  }, [unitIndex, unitContentState, getCurrentUnitQuery, unitCompletionState]);

  // effects
  useEffect(() => {
    if (!getExerciseQuery.isLoading && !getExerciseSidebarQuery.isLoading)
      setExerciseStatus("inProgress");
  }, [getExerciseQuery, getExerciseSidebarQuery]);

  useEffect(() => {
    if (getCurrentUnitQuery.isSuccess) {
      if (getCurrentUnitQuery.data) {
        setUnitStatus("question");
      } else {
        setExerciseStatus("finished");
        setUnitStatus("answer");
      }
    }
  }, [getCurrentUnitQuery]);

  // handlers
  const moveForward = async () => {
    // if index is not null move forward
    if (unitIndex !== null && unitIndex + 1 < units.length) {
      await moveToIndex(unitIndex + 1);
    } else {
      setUnitIndex(null);
    }
  };
  const moveBack = async () => {
    const nextIndex = unitIndex === null ? units.length - 1 : unitIndex - 1;
    await moveToIndex(nextIndex);
  };
  const moveToIndex = async (index: number) => {
    if (index < 0 || index >= units.length) return;

    setUnitStatus("loading");
    const nextUnit = units[index];
    if (nextUnit?.unitId === undefined) return;

    if (
      !activityState.isLoading &&
      !activityState.isSuccess &&
      unitContentState.fulfilledTimeStamp
    ) {
      postActivity({
        featureId: exerciseId,
        createUnitActivityRequest: {
          unitId: nextUnit.unitId,
          startTime: new Date(
            unitContentState.fulfilledTimeStamp
          ).toISOString(),
          endTime: new Date().toISOString(),
        },
      }).then(() => {
        activityState.reset();
      });
    }

    await fetchUnitContent({
      exerciseId,
      unitId: nextUnit.unitId,
      answerId: nextUnit.id,
    });
    setUnitIndex(index);
    setUnitStatus(unitContent.isCompleted ? "answer" : "question");
  };
  const moveNext = async () => {
    setUnitStatus("loading");
    if (unitStatus === "question" && unitContent.unit?.id) {
      await postSkip({ exerciseId, id: unitContent.unit?.id });
    }
    setUnitIndex(null);
    await getCurrentUnitQuery.refetch();

    unitCompletionState.reset();
    skipState.reset();
    if (getCurrentUnitQuery.data) {
      setUnitStatus("question");
    } else {
      setExerciseStatus("finished");
    }
  };
  const complete = async (completion: IUnitCompleted) => {
    await postUnitCompletion({
      exerciseId,
      id: completion.id || 0,
      iUnitCompleted: completion,
    }).then(() => {
      setUnitStatus("answer");
      getExerciseQuery.refetch();
      getExerciseSidebarQuery.refetch();
    });
  };

  return (
    <ExerciseContext.Provider
      value={{
        isLoading: exerciseStatus === "loading",
        isError: exerciseStatus === "error",
        exercise,
        units,
        currentIndex: unitIndex,
        currentUnit:
          (getCurrentUnitQuery.data as IUnit & { title: string }) || null,
        moveForward,
        moveBack,
        moveToIndex,
        moveNext,
        unitContent: {
          isLoading: unitStatus === "loading",
          isError: unitStatus === "error",
          data: unitContent,
        },
        unitCompletion: {
          isLoading: unitCompletionState.isLoading,
          post: complete,
          response: unitCompletionState.data,
        },
      }}
    >
      {children}
    </ExerciseContext.Provider>
  );
};
