import {
  useGetLoginProvidersByProviderUrlNameQuery,
  usePostAuthLoginMutation,
} from "../store/api/umbraco-api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { login } from "../store/session/actions/user.actions";
import LogoBox from "../components/header/logo-box";
import { Typography, Box, Input } from "@mui/joy";
import { get } from "lodash/fp";
import { TAUGA_AI_LOGO_1 } from "../images/images";
import LoginImage from "../images/Login_Image.png";
import { palette } from "../theme";
import SubmitButton from "../components/buttons";
import { setProvider } from "../store/provider-slice";

const Login = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const [mutate, { isSuccess, isLoading, data, isError }] =
    usePostAuthLoginMutation();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const { data: loginProvider } = useGetLoginProvidersByProviderUrlNameQuery({
    providerUrlName: provider || "",
  });

  useEffect(() => {
    if (provider) {
      dispatch(setProvider(provider));
    }
  }, [provider]);

  const onLogin = () => {
    mutate({
      loginRequest: {
        email,
        password,
        keepMeLoggedIn: true
      },
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      const { email, firstName, lastName } = data;

      if (email) {
        dispatch(
          login({
            email: email || "",
            firstName: firstName || "",
            lastName: lastName || "",
            isTeacher: get("isTeacher", data) || false,
          })
        );
      }
    }
  }, [data, dispatch, isSuccess]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: palette.purple_light,
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      {/* Left: Login Card */}
      <Box
        sx={{
          backgroundColor: palette.white,
          borderWidth: 1,
          borderColor: palette.gray_light,
          borderStyle: "solid",
          height: { md: "70%" },
          padding: { xs: 1, md: 4 },
          borderBottomLeftRadius: 50,
          borderBottomRightRadius: 50,
          width: { xs: "100%", lg: "30%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* Rest of the component remains the same */}
        {/* Logo and Welcome Text */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 2,
              marginY: 4,
            }}
          >
            {loginProvider?.logoUrl?.map((logo, index) => (
              <Box
                key={index}
                component="img"
                src={logo}
                alt={`Login Provider Logo ${index}`}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: 4,
                  objectFit: "contain",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            ))}
          </Box>
          <LogoBox imageSrc={TAUGA_AI_LOGO_1} imageHeight={60} />
          <Typography level="h1" sx={{ marginTop: 2, fontWeight: "bold" }}>
            ברוכים הבאים לטאוגה
          </Typography>
          <Typography
            level="body-lg"
            sx={{
              marginTop: 1,
              color: "gray",
              maxWidth: 400,
              lineHeight: 1.5,
            }}
          >
            עם טאוגה, תוכל ללמוד קורסים שונים בהתאמה לרמה שלך. החומר מתעדכן בזמן
            אמת כדי שתוכל להתקדם{" "}
            <Typography
              component="span"
              sx={{
                color: palette.purple,
                fontWeight: "bold",
              }}
            >
              בקצב שלך!
            </Typography>
          </Typography>
        </Box>

        {/* Input Fields */}
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Input
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="מייל"
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important",
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none",
              },
            }}
          />
          <Input
            disabled={isLoading}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="סיסמה"
            sx={{
              backgroundColor:
                palette.gray_light,
              width: "100%",
              maxWidth: 400,
              height: 50,
              border: "none !important",
              borderRadius: 1,
              fontSize: "1rem",
              "& fieldset": {
                border: "none",
              },
            }}
          />
        </Box>

        {/* Login Button */}
        <SubmitButton
          disabled={!email || !password || isLoading}
          text="התחברות"
          loadingText="מתחבר..."
          isLoading={isLoading}
          onClick={onLogin}
        />

        {/* Error Message */}
        {isError && (
          <Typography
            textAlign="center"
            sx={{
              marginTop: 2,
              color: palette.black,
              fontSize: "0.9rem",
            }}
          >
            שם המשתמש או הסיסמה שגויים, אנא נסה שוב.
          </Typography>
        )}
      </Box>
      <Box
        component="img"
        src={LoginImage}
        alt="Login illustration"
        sx={{
          justifySelf: "center",
          alignSelf: "center",
          objectFit: "cover",
          display: { xs: "none", lg: "block" },
        }}
      />
    </Box>
  );
};

export default Login;
