import { Box, Typography } from "@mui/material";
import { palette } from "../../theme";
import MessageRenderer from "./message-renderer";

interface Message {
  sender: string;
  text: string;
}

export const MessageBox = ({
  message,
  index,
}: {
  message: Message;
  index: number;
}) => {
  const isUser = message.sender === "User";
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isUser ? "flex-start" : "flex-end",
        width: "100%",
        marginBottom: 1,
      }}
    >
      <Box
        sx={{
          color: isUser ? palette.white : palette.purple,
          borderRadius: "12px",
          backgroundColor: isUser ? palette.purple : palette.purple_light,
          padding: "10px 16px",
          wordBreak: "break-word",
          maxWidth: "80%",
          borderTopRightRadius: isUser ? "4px" : "12px",
          borderTopLeftRadius: isUser ? "12px" : "4px",
        }}
      >
        <Typography variant="body2">
          <MessageRenderer text={message.text} />
        </Typography>
      </Box>
    </Box>
  );
};
