import { Typography, Box, Button } from "@mui/joy";
import { useMemo, useState, useRef } from "react";
import { isMobile } from "react-device-detect";

import { IUnitCompleted } from "../../../store/api/umbraco-api";
import { palette } from "../../../theme";
import { UnitProps } from "../interfaces";
import { PrimaryActionButton, SecondaryActionButton  } from "../shared/btn";
import Loading from "../shared/loading";
import { uploadImages } from "./upload-images";
import Answer from "./answer";
import useUnit from "../../../context/use-unit";
import { Camera, Close, Send, UploadFile } from "@mui/icons-material";


const ImageInputs = ({
  unitId,
  unitType,
  solution,
}: UnitProps & { solution?: string | null }) => {
  const { unitCompletion } = useUnit();
  const startTime = useMemo(() => new Date(), []);
  const [images, setImages] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cameraInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadUrls, setUploadUrls] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...selectedFiles]);
      e.target.value = ""; // Reset the input to allow re-upload of the same file
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleSend = async () => {
    try {
      setIsLoading(true);
      if (images.length === 0) return;

      const urls = await uploadImages(unitId, images);
      if (!urls?.length) return;
      setUploadUrls(urls);
      unitCompletion.post({
        id: unitId,
        type: unitType,
        start: startTime.toISOString(),
        end: new Date().toISOString(),
        answer: urls.join(","),
      } as IUnitCompleted);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  if (unitCompletion.isLoading || isLoading) {
    return <Loading />;
  }

  if (unitCompletion.response) {
    return (
      <Answer solution={solution} {...unitCompletion.response}>
        <Box>
          {uploadUrls.map((url, index) => (
            <img src={url} alt={`תמונה מס' ${index+1}`}/>
          ))}
        </Box>
      </Answer>
    );
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: "none" }}
        disabled={unitCompletion.isLoading || isLoading}
      />
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageUpload}
        ref={cameraInputRef}
        style={{ display: "none" }}
        disabled={unitCompletion.isLoading || isLoading}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <SecondaryActionButton
          text="העלה תמונות"
          iconSide="right"
          icon={<UploadFile />}
          onClick={handleButtonClick}
          disabled={unitCompletion.isLoading || isLoading}
        />

        {isMobile && (
          <Button
            sx={{ gap: 2 }}
            onClick={handleCameraClick}
            variant="outlined"
            disabled={unitCompletion.isLoading || isLoading}
          >
            <Camera/>
            צלם תמונה
          </Button>
        )}
      </Box>
      <Box
        sx={{
          gap: 1,
          display: "flex",
          paddingY: 1,
          flexWrap: "wrap",
        }}
      >
        {images.map((image, index) => (
          <Button
            onClick={() => handleRemoveImage(index)}
            key={index}
            sx={{
              alignSelf: "flex-start",
              gap: 1,
              width: "fit-content",
              display: "flex",
              backgroundColor:
                palette.gray_light,
              color: palette.purple,
              borderColor: palette.purple,
              "&:hover": {
                backgroundColor: palette.gray,
                color: palette.white,
              },
            }}
          >
            <Close sx={{ fontSize: 14 }} />
            <Typography fontSize={12}>{image.name}</Typography>
          </Button>
        ))}
      </Box>

      <Box>
        <PrimaryActionButton
          text="שלח תמונות"
          iconSide="left"
          icon={<Send sx={{ fontSize: 14, transform: "rotate(180deg)" }} />}
          onClick={handleSend}
          disabled={
            images.length === 0 || isLoading || unitCompletion.isLoading
          }
        />
      </Box>
    </>
  );
};

export default ImageInputs;
