import React from "react";
import useNavigateWithProvider from "../../hooks/use-navigate-with-provider";

interface LinkWithProviderProps extends React.HTMLAttributes<HTMLElement> {
  to: string;
  children: React.ReactNode;
  component?: 'button' | 'a';
  external?: boolean;
}

function LinkWithProvider({
  to,
  children,
  component = 'button',
  external = false,
  style,
  ...props
}: LinkWithProviderProps) {
  const navigateWithProvider = useNavigateWithProvider();

  const baseStyles: React.CSSProperties = {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    ...style,
  };

  if (external) {
    return (
      <a 
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        style={baseStyles}
        {...props}
      >
        {children}
      </a>
    );
  }

  const Component = component as any;
  return (
    <Component
      style={baseStyles}
      onClick={() => navigateWithProvider(to)}
      {...props}
    >
      {children}
    </Component>
  );
}

export default LinkWithProvider;
