import React, { ReactNode, useState } from "react";
import { Box, Typography, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowLeft, CheckCircle } from "@mui/icons-material";
import { palette } from "../../../../theme";
import { LessonUnit, LessonResponse } from "../../../../store/api/umbraco-api";
import { useSidebar } from "../../../../context/sidebar-context";

interface LessonSideBarProps extends Omit<LessonResponse, "id"> {
  units?: LessonUnit[];
  isMobile?: boolean;
  setSelectedUnit: (unit: LessonUnit) => void;
  selectedUnit: LessonUnit;
}

const LessonSideBar = ({
  name,
  totalUnits = 0,
  completedUnits = 0,
  units = [],
  setSelectedUnit,
  selectedUnit,
}: LessonSideBarProps) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <Box
        sx={{
          borderBottom: "1px solid #E5E7EB",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0, // Prevents header from shrinking
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
            color: palette.black,
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            color: palette.purple,
          }}
        >
          {completedUnits}/{totalUnits}
        </Typography>
      </Box>

      {/* Progress Bar */}
      <Box sx={{ 
        display: "flex", 
        flexDirection: "column", 
        gap: 1,
        padding: 2,
        flexShrink: 0 // Prevents progress bar from shrinking
      }}>
        <Box
          sx={{
            height: "10px",
            backgroundColor: palette.purple_light,
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: `${
                totalUnits > 0 ? (completedUnits / totalUnits) * 100 : 0
              }%`,
              height: "100%",
              backgroundColor: palette.purple,
              transition: "width 0.3s ease-in-out",
            }}
          />
        </Box>
      </Box>

      {/* Units List */}
      <Box
        sx={{
          flex: 1, // Takes remaining space
          overflowY: "auto", // Allows scrolling if content is too long
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: 2,
        }}
      >
        {units.map((unit) => (
          <UnitListItem
            key={unit.id}
            unit={unit}
            isCurrent={unit.id === selectedUnit.id}
            onClick={() => setSelectedUnit(unit)}
          />
        ))}
      </Box>
    </Box>
  );
};

interface UnitListItemProps {
  unit: LessonUnit;
  isCurrent: boolean;
  onClick: () => void;
}
const UnitListItem = ({ unit, isCurrent, onClick }: UnitListItemProps) => {
  return (
    <Box
      key={unit.id}
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: isCurrent
          ? palette.green // Highlight current unit
          : palette.background,
        borderRadius: "12px",
        padding: "24px",
        cursor: "pointer",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          color: palette.black,
          flexGrow: 1,
        }}
      >
        {unit.name}
      </Typography>
      {unit.isComplete && (
        <CheckCircle
          sx={{
            color: palette.green_finish,
          }}
        />
      )}
    </Box>
  );
};

const MobileDrawer = ({ children }: { children: ReactNode }) => {
    const { setIsOpen, isOpen } = useSidebar();

  return (
    <>
      <Box
        onClick={() => setIsOpen(true)}
        sx={{
          height: "100%",
          backgroundColor: palette.white,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: palette.gray_light,
          borderStyle: "solid",
          justifyContent: "center",
          display: "grid",
        }}
      >
        <IconButton
          sx={{
            borderRadius: 0,
            color: palette.purple,
          }}
        >
          <ArrowLeft sx={{ fontSize: "40px" }} />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box sx={{ padding: 2 }}>
          <IconButton
            onClick={() => setIsOpen(false)}
            sx={{ marginBottom: 2 }}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </Box>
      </Drawer>
    </>
  );
};

const LessonSidebar = ({ isMobile = false, ...props }: LessonSideBarProps) => {
  if (isMobile) {
    return (
      <MobileDrawer>
        <LessonSideBar {...props} />
      </MobileDrawer>
    );
  }

  return <LessonSideBar {...props} />;
};

export default LessonSidebar;
