import { Box, Typography } from "@mui/joy";
import Icon from "../../../components/common/icon";
import {
  SportsMartialArts,
} from "@mui/icons-material";
import { themeBase } from "../../../theme";
import useNavigateWithProvider from "../../../hooks/use-navigate-with-provider";
import SubmitButton from "../../../components/buttons";
import RichText from "../../../components/rich-text";

const defaultMessage = `<h3>
  כל הכבוד, סיימת את הפרק! הידע שלך הוא ב 15% העליונים של התלמידים בפרק
  זה, נראה שאתה תותח אמיתי בנושא זה! המשך כך!</h3>
`;

const FeatureFinished = ({ message } : { message?: string }) => {
  const navigateWithProvider = useNavigateWithProvider();

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 5 }}>
      <RichText content={message || defaultMessage} />
      <Icon
        component={SportsMartialArts}
        sx={{ fontSize: 60 }}
        htmlColor={themeBase.colorSchemes.light.palette.primary[500]}
      />
      <Box sx={{ gap: 2, display: "flex", flexDirection: "row" }}>
        <SubmitButton
          text={"חזרה לקורס"}
          loadingText={"חזרה לקורס"}
          isLoading={false}
          disabled={false}
          onClick={() => navigateWithProvider("/")}
        />
      </Box>
    </Box>
  );
};

export default FeatureFinished;
