import { useContext } from "react";
import { ExerciseContext } from "./exercise-context";
const useExercise = () => {
  const context = useContext(ExerciseContext);
  if (context === null) {
    throw new Error(
      "Cant use exercise context outside of exercise context provider"
    );
  }

  return context;
};

export default useExercise;