import { useState, useEffect } from "react";
import { Box } from "@mui/joy";
import Chat from "./side-chat";
import { palette } from "../../theme";

const ResizableChat = ({ resourceId }: { resourceId: number }) => {
  const [width, setWidth] = useState(400);
  const [isResizing, setIsResizing] = useState(false);
  const minWidth = 300;
  const maxWidth = 800;

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing) return;
      const newWidth = e.clientX; // Measure distance from the left
      setWidth(Math.min(Math.max(newWidth, minWidth), maxWidth));
    };

    const handleMouseUp = () => {
      setIsResizing(false);
      document.body.style.cursor = "default";
      document.body.style.userSelect = "";
    };

    if (isResizing) {
      document.body.style.cursor = "col-resize";
      document.body.style.userSelect = "none";
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <Box
      sx={{
        position: "relative",
        width: `${width}px`,
        borderLeft: "1px solid",
        borderColor: "divider",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",

      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: 20,
          right: -2,
          top: 0,
          cursor: "col-resize",
          bottom: 0,
          backgroundColor: isResizing ? `${palette.purple}80` : "transparent",
          transition: "background-color 0.2s ease",
          zIndex: 10,
          "&:hover": {
            backgroundColor: `${palette.purple}40`,
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          setIsResizing(true);
        }}
      />
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Chat resourceId={resourceId} />
      </Box>
    </Box>
  );
};

export default ResizableChat;
