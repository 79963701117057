import { useMemo } from "react";
import { Typography } from "@mui/material";
import Markdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

interface MessageRendererProps {
  text: string;
}

const NewMessageRenderer = ({ text }: MessageRendererProps) => {
  const formattedText = useMemo(() => 
    text.replace(/(\\\(|\\\))/g, '$').replace(/(\\\[|\\\])/g, '$$'), 
    [text]
  );

  return (
    <Typography sx={{
      "& .katex-html": {
        display: "none"
      },
      "& .katex-mathml": {
        fontSize: 20
      },
      "& p": {
        marginBlock: "8px"
      }
    }}>
      <Markdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
      >
        {formattedText}
      </Markdown>
    </Typography>
  );
}

export default NewMessageRenderer;
