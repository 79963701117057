import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../header";
import { userSelector } from "../../store/session/selectors";
import useNavigateWithProvider from "../../hooks/use-navigate-with-provider";
import { palette } from "../../theme";
import Footer from "../footer";

const AuthenticatedRoute: React.FC = () => {
  const user = useSelector(userSelector);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const navigateWithProvider = useNavigateWithProvider();

  useEffect(() => {
    if (!user) {
      navigateWithProvider("/login");
    }
  }, [user]);

  return (
    <Box sx={styles.root}>
      <Header />
      <Box sx={styles.main}>
        {isLargeScreen ? (
          <Box component="main" sx={styles.largeContent}>
            <Outlet />
          </Box>
        ) : (
          <>
            <Box component="main" sx={styles.smallContent}>
              <Outlet />
            </Box>
          </>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default AuthenticatedRoute;

const styles = {
  root: {
    bgcolor: palette.background,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  main: {
    display: "flex",
    height: "calc(100vh - 128px)",
    minHeight: 0,
  },
  largeContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingX: {
      xs: "6px",
      sm: "20px",
    },
    paddingY: 2,
    overflow: "hidden",
  },
  smallContent: {
    flex: 1,
    paddingX: {
      xs: "6px",
      sm: "20px",
      md: "80px",
    },
    paddingY: 2,
    overflow: "hidden",
  },
};
