import { Box } from "@mui/material";
import { MessageBox } from "./message-box";
import { TypingIndicator } from "./typing-indicator";
import { useEffect, useRef } from "react";

interface Message {
  sender: string;
  text: string;   
}

export const MessagesList = ({
  messages,
  isBotTyping,
  contentRef = null,
  streamingMessage = "",
  isStreaming = false,
}: {
  messages: Message[];
  isBotTyping: boolean;
  contentRef?: React.RefObject<HTMLDivElement> | null;
  streamingMessage?: string;
  isStreaming?: boolean;
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targetRef = contentRef?.current || listRef.current;
    if (targetRef) {
      targetRef.scrollTo({
        top: targetRef.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [messages, isBotTyping, streamingMessage]);

  return (
    <Box
      ref={listRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
        marginTop: "auto",
        maxHeight: contentRef ? "none" : "100%",
        overflowY: contentRef ? "visible" : "auto",
      }}
    >
      {messages.map((message, index) => (
        <Box key={index}>
          <MessageBox message={message} index={index} />
        </Box>
      ))}
      {isStreaming && (
        <Box>
          <MessageBox 
            message={{ sender: "AI", text: streamingMessage }} 
            index={messages.length} 
          />
        </Box>
      )}
      {isBotTyping && !isStreaming && (
        <Box sx={{ alignSelf: "flex-end", paddingBottom: 2 }}>
          <TypingIndicator />
        </Box>
      )}
    </Box>
  );
};
