import React, { ReactNode, useState } from "react";
import { Box, Typography, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {  Clear, Done } from "@mui/icons-material";
import { palette } from "../../../../theme";
import { ExerciseResponse } from "../../../../store/api/umbraco-api";
import { useSidebar } from "../../../../context/sidebar-context";

interface ExerciseSidebarProps extends Omit<ExerciseResponse, "id"> {
  units?: { name: string; isCorrect?: boolean }[];
  isMobile?: boolean;
  setSelectedUnit: (index: number) => void;
  currentIndex: number | null;
  currentUnit: { name: string } | null;
  moveToCurrent: () => void;
}

const ExerciseSidebar = ({
  name,
  totalUnits = 0,
  completedUnits = 0,
  units = [],
  setSelectedUnit,
  currentIndex,
  currentUnit,
  moveToCurrent,
}: ExerciseSidebarProps) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <Box
        sx={{
          borderBottom: "1px solid #E5E7EB",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
            color: palette.black,
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            color: palette.purple,
          }}
        >
          {completedUnits}/{totalUnits}
        </Typography>
      </Box>

      {/* Progress Bar */}
      <Box sx={{ 
        display: "flex", 
        flexDirection: "column", 
        gap: 1,
        padding: 2,
        flexShrink: 0
      }}>
        <Box
          sx={{
            height: "10px",
            backgroundColor: palette.purple_light,
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: `${
                totalUnits > 0 ? (completedUnits / totalUnits) * 100 : 0
              }%`,
              height: "100%",
              backgroundColor: palette.purple,
              transition: "width 0.3s ease-in-out",
            }}
          />
        </Box>
      </Box>

      {/* Units List */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: 2,
        }}
      >
        {units.map(
          (unit, index) =>
            unit.name && (
              <UnitListItem
                key={index}
                name={unit.name}
                isCorrect={unit.isCorrect}
                isCurrent={currentIndex === index}
                onClick={() => setSelectedUnit(index)}
              />
            )
        )}
        {currentUnit && (
          <>
            <Typography sx={{ px: 1, py: 2 }}>התרגיל הנוכחי:</Typography>
            <UnitListItem
              isCurrent={currentIndex === null}
              name={currentUnit.name}
              onClick={moveToCurrent}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

interface UnitListItemProps {
  name: string;
  isCorrect?: boolean;
  isCurrent: boolean;
  onClick: () => void;
}
const UnitListItem = ({
  name,
  isCorrect,
  isCurrent,
  onClick,
}: UnitListItemProps) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: isCurrent
          ? palette.green // Highlight current unit
          : palette.background,
        borderRadius: "12px",
        padding: "24px",
        cursor: "pointer",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "16px",
          fontWeight: 600,
          color: palette.black,
          flexGrow: 1,
        }}
      >
        {name}
        {isCorrect !== undefined &&
          (isCorrect ? <Done color="success" /> : <Clear color="error" />)}
      </Typography>
    </Box>
  );
};

const MobileDrawer = ({ children }: { children: ReactNode }) => {
  const { isOpen, setIsOpen } = useSidebar();
  
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Box sx={{ 
        padding: 2,
        height: '100%',
        width: '300px'
      }}>
        <IconButton
          onClick={() => setIsOpen(false)}
          sx={{ marginBottom: 2 }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Drawer>
  );
};

const ExerciseSidebarWrapper = ({ isMobile = false, ...props }: ExerciseSidebarProps) => {
  if (isMobile) {
    return (
      <MobileDrawer>
        <ExerciseSidebar {...props} />
      </MobileDrawer>
    );
  }

  return <ExerciseSidebar {...props} />;
};

export default ExerciseSidebarWrapper;
