import { Typography, Box } from "@mui/material";
import { palette } from "../../theme";

export const TypingIndicator = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "8px",
      width: "60px", // Set width for the small box
      height: "20px", // Set height for the small box
    //   border: `1px solid ${palette.purple_dark}`, // Optional: border around the box
      borderRadius: "8px", // Optional: rounded corners
      backgroundColor: palette.purple_light
    }}
  >
    <Typography
      variant="body2"
      color={palette.purple_dark}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px", // Increased size for the dots
        gap: "8px",
        "@keyframes jump": {
          "0%": {
            transform: "translateY(0)",
          },
          "50%": {
            transform: "translateY(-10px)", // Adjust jump height
          },
          "100%": {
            transform: "translateY(0)",
          },
        },
      }}
    >
      <span
        style={{
          animation: "jump 1s infinite 0s",
        }}
      >
        .
      </span>
      <span
        style={{
          animation: "jump 1s infinite 0.2s",
        }}
      >
        .
      </span>
      <span
        style={{
          animation: "jump 1s infinite 0.4s",
        }}
      >
        .
      </span>
    </Typography>
  </Box>
);
