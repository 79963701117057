import { Box, Typography } from "@mui/joy";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IUnit, IUnitCompleted } from "../../../store/api/umbraco-api";
import RichText from "../../rich-text";
import { UnitProps } from "./../interfaces";
import Error from "./../shared/error";
import Loading from "./../shared/loading";
import ImageInputs from "./ImageInputs";
import TextInputs from "./text-inputs";
import { ImageAnswer, TextAnswer } from "./answer";
import useUnit from "../../../context/use-unit";
import { UnitContentResponse } from "../../../context/unit-context";
import { useMemo, useState } from "react";
import { SecondaryActionButton } from "../shared/btn";

interface OpenExerciseQuestionResponse extends UnitContentResponse {
  unit: IUnit & {
    title: string;
    solution?: string | null;
    question: string;
    answerType: string;
    isRevealSolution: boolean;
  };
  completion: IUnitCompleted & { answer: string };
}

const OpenExerciseQuestion = ({ unitId, unitType }: UnitProps) => {
  const { unitContent, unitCompletion } =
    useUnit<OpenExerciseQuestionResponse>();
  const [isRevealed, setIsRevealed] = useState(false);

  const canReveal = useMemo(
    () =>
      unitContent.data?.isRevealSolution &&
      unitContent.data.unit.solution &&
      unitContent.data.unit.solution.length > 0,
    [unitContent]
  );

  const imageUrls = useMemo(
    () => unitContent.data?.completion?.answer?.split(",") || [],
    [unitContent]
  );

  const isCompleted = useMemo(
    () =>
      unitContent.data?.isCompleted || unitCompletion.response !== undefined,
    [unitContent, unitCompletion]
  );

  if (unitContent.isLoading || !unitContent.data) {
    return <Loading />;
  }

  if (unitContent.isError) {
    return <Error />;
  }

  const { unit, completion } = unitContent.data;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {unit.title && (
        <Typography level="title-lg" sx={{ fontWeight: "bold" }}>
          {unit.title}
        </Typography>
      )}
      <RichText content={unit.question} />

      {!isCompleted &&
        (unit.answerType === "image" ? (
          <ImageInputs
            unitId={unitId}
            unitType={unitType}
            solution={unit.solution}
          />
        ) : (
          <TextInputs
            unitId={unitId}
            unitType={unitType}
            solution={unit.solution}
          />
        ))}

      {canReveal && isRevealed && (
        <Box sx={{ mt: 2, p: 2, border: "1px solid", borderRadius: 2 }}>
          <Typography level="title-md" sx={{ fontWeight: "bold", mb: 1 }}>
            פתרון
          </Typography>
          <RichText content={unit.solution || ""} />
        </Box>
      )}

      {isCompleted &&
        (unit.answerType === "image" ? (
          <ImageAnswer imageUrls={imageUrls} solution={unit.solution} />
        ) : (
          <TextAnswer text={completion?.answer} solution={unit.solution} />
        ))}

      {!isCompleted && canReveal && (
        <SecondaryActionButton
          text={isRevealed ? "הסתר פתרון" : "הצג פתרון"}
          iconSide="left"
          icon={
            isRevealed ? (
              <VisibilityOff sx={{ fontSize: 16 }} />
            ) : (
              <Visibility sx={{ fontSize: 16 }} />
            )
          }
          onClick={() => setIsRevealed(!isRevealed)}
        />
      )}  
    </Box>
  );
};

export default OpenExerciseQuestion;
