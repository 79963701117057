import { Typography } from "@mui/material";

import { Box } from "@mui/material";
import RichText from "../rich-text";
import { palette } from "../../theme";

interface UnitHeaderProps {
  title: string;
  content: string;
}

export const UnitHeader = ({ title, content }: UnitHeaderProps) => {
  return (
    <Box>
      {title && (
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: palette.black,
            marginBottom: "2px",
          }}
        >
          {title}
        </Typography>
      )}
      {content && (
        // <Typography
        //   sx={{
        //     color: palette.gray,
        //     fontSize: "0.85rem",
        //     opacity: 0.8,
        //   }}
        // >
        <RichText content={content} />
        // </Typography>
      )}
    </Box>
  );
};
