import React from "react";
import { Box, IconButton } from "@mui/joy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { palette } from "../../../theme";

interface NavigationButtonsProps {
  onBack: () => void;
  onNext: () => void;
  disableBack: boolean;
  disableNext: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  onBack,
  onNext,
  disableBack,
  disableNext,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", p:2 }}>
      <IconButton
        sx={{
          transition: "all 0.2s ease",
          backgroundColor:
            palette.purple_light,
          color: palette.purple,
          "&:hover": {
            backgroundColor:
              palette.purple, // Custom hover color
            color: palette.white, // Text/icon hover color
          },
        }}
        onClick={onBack}
        disabled={disableBack}
      >
        <ArrowForwardIosIcon
          sx={{
            marginX: 1,
          }}
        />
      </IconButton>
      <IconButton
        sx={{
          transition: "all 0.2s ease",
          backgroundColor:
            palette.purple_light,
          color: palette.purple,
          paddingX: 1,
          py: 1,
          "&:hover": {
            backgroundColor:
              palette.purple, // Custom hover color
            color: palette.white, // Text/icon hover color
          },
        }}
        onClick={onNext}
        disabled={disableNext}
      >
        <ArrowBackIosIcon
          sx={{
            marginLeft: 1,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default NavigationButtons;
