import { Button } from "@mui/joy";
import { palette } from "../../../theme";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  iconSide?: "left" | "right";
}

export const PrimaryActionButton = ({
  text,
  icon,
  onClick,
  disabled,
  iconSide = "right",
}: ButtonProps) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={{ ...styles.baseButton, ...styles.primaryActionButton }}
  >
    {iconSide === "right" && icon && icon}
    {text}
    {iconSide === "left" && icon && icon}
  </Button>
);

export const SecondaryActionButton = ({
  text,
  icon,
  onClick,
  disabled,
  iconSide = "right",
}: ButtonProps) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={{ ...styles.baseButton, ...styles.secondaryActionButton }}
  >
    {iconSide === "right" && icon && icon}
    {text}
    {iconSide === "left" && icon && icon}
  </Button>
);

// Define all button styles in one object
const styles = {
  baseButton: {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "6px 12px",
    borderRadius: "6px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "all 0.2s ease",
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  primaryActionButton: {
    justifyContent: "center",
    alignItems: "center",
    color: palette.white,
    backgroundColor: palette.purple,
    "&:hover": {
      backgroundColor: palette.purple_dark,
    },
    "&:disabled": {
      backgroundColor: palette.gray_light,
    },
  },
  secondaryActionButton: {
    border: `1px solid ${palette.purple}`,
    color: palette.purple,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: palette.purple,
      color: palette.purple_light,
    },
  },
};
