import { IUnit } from "../../store/api/umbraco-api";

export interface ContentUnit extends IUnit {
  id: number;
  title: string;
  content: string;
}

export interface OpenResponseExercise extends IUnit {
  id: number;
  title: string;
  solution?: string;
  question: string;
  answerType: "image" | "text";
}

export interface MultiChoiceExercise extends IUnit {
  id: number;
  title: string;
  question: string;
  answers: string[];
  correctAnswer: number;
  solution?: string;
}

export interface AIConversationExercise extends IUnit {
  id: number;
  title: string;
  content: string;
}

export enum UnitTypes {
  ContentUnit = 0,
  OpenResponseExercise = 1,
  MultiChoiceExercise = 2,
  Form = 3,
  AIConversation = 4,
}

export function isContentUnit(unit: IUnit): unit is ContentUnit {
  return (unit.type as unknown as UnitTypes) === UnitTypes.ContentUnit;
}

export function isOpenResponseExercise(
  unit: IUnit
): unit is OpenResponseExercise {
  return (unit.type as unknown as UnitTypes) === UnitTypes.OpenResponseExercise;
}

export function isMultiChoiceExercise(
  unit: IUnit
): unit is MultiChoiceExercise {
  return (unit.type as unknown as UnitTypes) === UnitTypes.MultiChoiceExercise;
}

export function isAIConversation(unit: IUnit): unit is AIConversationExercise {
  return (unit.type as unknown as UnitTypes) === UnitTypes.AIConversation;
}
