import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import session from "./session";
import providerReducer from "./provider-slice";
import selectedCourseReducer from "./course-slice"; // Import selectedCourseReducer
import { baseApi as api } from "./api/base-api";
import timerReducer from "./timer-slice"; // Adjust the path to match your project structure
import { authenticationMIddleware } from "./api/api-middlware";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [api.reducerPath, "selectedCourse"], // Exclude selectedCourse from persistence
};

const rootReducer = combineReducers({
  session,
  provider: providerReducer,
  timer: timerReducer,
  selectedCourse: selectedCourseReducer, // Add selectedCourse here
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([api.middleware, authenticationMIddleware]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

